import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
// import { diagnosesMap } from 'src/components/questionnaire/Constants';
// import { diagnsesShapeMap } from '../pedigreeUtil';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { PEDIGREE } from 'src/components/Constants';
// import DiagnosisModal from '../DiagnosisModal';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: 15,
    flexWrap: 'wrap'
  },
  boxHolder: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '1rem'
  },
  item: {
    display: 'inline-block',
    flexShrink: 0,
    width: 15,
    height: 15,
    borderRadius: 2,
    marginRight: 8
  }
});

export default function LegendMenu({ diagnoses, id, labelsSet, setLabelsSet }) {
  const classes = useStyles();

  const handleChange = (e) =>
    setLabelsSet({ ...labelsSet, [e.target.name]: e.target.checked });

  const labels = {
    diseases: 'Diseases'
  };

  const t = useAutoTranslation();
  return (
    <>
      <Grid item>
        {labelsSet && (
          <FormGroup column>
            {Object.keys(PEDIGREE.legendLabelFilters).map((label) => {
              return (
                <FormControlLabel
                  key={label}
                  control={
                    <Checkbox
                      checked={labelsSet[label]}
                      onChange={handleChange}
                      size="small"
                      name={label}
                    />
                  }
                  label={t(labels[label])}
                />
              );
            })}
          </FormGroup>
        )}

        {(!labelsSet || labelsSet?.diseases) && (
          <Grid container className={classes.container} id={id}>
            {diagnoses.map((el, i) => {
              return (
                <div key={i} className={classes.boxHolder}>
                  <span
                    className={classes.item}
                    style={{
                      backgroundColor: el.color ?? 'black'
                    }}
                  ></span>
                  <span>{t(el.name)}</span>
                </div>
              );
            })}
          </Grid>
        )}
      </Grid>
    </>
  );
}
