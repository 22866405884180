import React from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { CircularProgress, Typography } from '@mui/material';

import axios from 'src/axios';
import TextInput from 'src/components/UI/input/TextInput';
import BoxContainer from 'src/components/UI/container/BoxContainer';
import { validateEmail } from 'src/util/util';
import { LoadingContext } from 'src/store/ContextStore';
import { GLOBAL_CONSTANTS } from 'src/components/Constants';
import { APICONSTANTS, CONSTANTS } from './Constants';
import FormLabel from 'src/components/UI/input/CustomFormLabel';
import DateInput from 'src/components/UI/input/DateInput';
import { formatDate } from 'src/util/util';

export default function EmailAuthentication({ setAuth }) {
  // from the url fetch token
  const params = new URLSearchParams(window.location.search);
  const token = params.get(GLOBAL_CONSTANTS.tokenKey);

  // used to extract or save b64 encoded email to localstorage
  const key = React.useMemo(() => {
    const _splitToken = token.split('.');
    return _splitToken[_splitToken.length - 1];
  }, [token]);

  const [email, setEmail] = React.useState('');
  const [userDetails, setUserDetails] = React.useState({
    hospitalNumber: '',
    dob: ''
  });
  const [showError, setShowError] = React.useState(false);
  const [showFields, setShowFields] = React.useState(false);
  const { loading, setLoading } = React.useContext(LoadingContext);
  const [btnLoading, setBtnLoading] = React.useState(false);

  const verifyAuthentication = (_be_fhq) => {
    let formattedDate = formatDate(userDetails['dob']);

    return axios
      .post(
        APICONSTANTS.verifyEmail,
        {
          _be_fhq,
          hospitalNumber: userDetails['hospitalNumber'],
          dob: formattedDate
        },
        {
          headers: { [CONSTANTS.authTokenKey]: token }
        }
      )
      .then((res) => {
        if (res.data.isValid) setAuth(true);
        else setShowError(res.data.error);
      })
      .catch((err) =>
        setShowError(err.response.data.error ?? 'An error has occurred!')
      )
      .finally(() => {
        setLoading(false);
        setBtnLoading(false);
      });
  };

  const checkFormLink = () => {
    axios
      .get(APICONSTANTS.verifyFormLink, {
        headers: { [CONSTANTS.authTokenKey]: token }
      })
      .then((res) => {
        if (res.data.showFields !== undefined) {
          setShowFields(res.data.showFields);
        }
      })
      .catch((err) => {
        console.log('Errr-->>', err);
      });
  };

  React.useEffect(() => {
    setLoading(true);

    // Check form submission is linked with a family member or not
    checkFormLink();

    // check localstorage - if present pass it to parent
    const _be_fhq = window.localStorage.getItem(key);
    if (_be_fhq) {
      verifyAuthentication(_be_fhq);
    } else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function to encode email to base64, pass it to parent, save to localstorage
  const submitHandler = (e) => {
    e.preventDefault();
    setShowError('');
    const input = email.trim();
    // encode to base64
    if (validateEmail(input)) {
      const _be_fhq = btoa(input);
      // const _be_fhq = Buffer.from(input).toString('base64');
      window.localStorage.setItem(key, _be_fhq);
      setBtnLoading(true);
      verifyAuthentication(_be_fhq);
    } else setShowError('Invalid email!');
  };

  const handleChange = (e) => {
    setUserDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const dateChangeHandler = (type, value) => {
    let formattedDate = formatDate(value);
    let invalidDate = formattedDate.includes('NaN');

    setUserDetails((prevState) => ({
      ...prevState,
      [type]: value
    }));
  };

  return (
    <BoxContainer>
      <form className={`user-form`} onSubmit={submitHandler}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {showError && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {showError}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>
              To verify the access, please enter the email this link was sent to
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              autoFocus
              // updateParent
            />
          </Grid>

          {/* Only display DOB, Hospital number field if link is not created */}
          {showFields && (
            <>
              {/* DOB */}
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Date of Birth
                </FormLabel>

                <DateInput
                  value={userDetails.dob}
                  onChange={(date) => {
                    dateChangeHandler('dob', date);
                  }}
                  disableFuture
                  liveText={true}
                  helperTextColour="red"
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Grid>

              {/* Hospital Number */}
              <Grid item xs={12}>
                <TextInput
                  label="Hospital Number"
                  name="hospitalNumber"
                  value={userDetails.hospitalNumber}
                  onChange={handleChange}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="small"
              disabled={
                loading ||
                btnLoading ||
                !email ||
                (showFields && !userDetails.dob)
              }
              endIcon={btnLoading ? <CircularProgress size={15} /> : undefined}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </BoxContainer>
  );
}
