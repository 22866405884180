import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Box,
  Typography,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'src/axios'; // Your customised axios instance
import HistoryIcon from '@mui/icons-material/History';
import {
  APICONSTANTS,
  CONSTANTS,
  HPO_CONSTANTS
} from 'src/components/Constants';
import { SnackContext, AppContext } from 'src/store/ContextStore';
import { getHPOSearchURL } from 'src/util/util';
import { useAutoTranslation } from 'src/hooks/useTranslate';

// ------------------ Genotype Modal ------------------ //
const GenotypeModal = ({ open, onClose, genotype, onSave, user }) => {
  const [clinicallyConfirmed, setClinicallyConfirmed] = useState(false);
  const [patientReported, setPatientReported] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [displayOnPedigree, setDisplayOnPedigree] = useState(false);
  const [dateRecorded, setDateRecorded] = useState('');
  const [recordedBy, setRecordedBy] = useState('');
  const [carrierStatus, setCarrierStatus] = useState('');

  // ----- NEW: ClinVar-related state -----
  const [clinVarResults, setClinVarResults] = useState([]);
  const { setSnack } = useContext(SnackContext);

  // When genotype or modal is opened, initialise fields
  useEffect(() => {
    if (genotype) {
      setClinicallyConfirmed(genotype.clinicallyConfirmed || false);
      setPatientReported(genotype.patientReported || false);
      setIsPrivate(genotype.isPrivate || false);
      setDisplayOnPedigree(genotype.displayOnPedigree || false);
      setDateRecorded(
        genotype.dateRecorded || new Date().toISOString().split('T')[0]
      );
      setRecordedBy(
        genotype.recordedBy || `${user.firstName} ${user.lastName}`
      );
      setCarrierStatus(genotype.carrierStatus || '');
    }
  }, [genotype, user]);

  // ----- NEW: The function to fetch and parse ClinVar data -----
  const fetchClinVarData = (fullId) => {
    // Safely extract 'NCBIGene:####' part before any spaces
    const textBeforeSpace = fullId.split(' ')[0];
    const geneId = textBeforeSpace.replace('NCBIGene:', ''); // Keep only numbers

    // Step 1: Search gene ID in ClinVar database
    axios
      .get('https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi', {
        params: {
          db: 'clinvar',
          term: `${geneId}[geneid]`,
          retmode: 'json'
        }
      })
      .then(({ data }) => {
        const idList = data?.esearchresult?.idlist || [];

        if (idList.length > 0) {
          // Step 2: Fetch summary details for IDs
          axios
            .get(
              'https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi',
              {
                params: {
                  db: 'clinvar',
                  id: idList.join(','), // Use comma-separated ID list
                  retmode: 'json'
                }
              }
            )
            .then(({ data: summaryData }) => {
              const results = summaryData?.result || {};
              const variants = [];

              idList.forEach((variantId) => {
                const variantTitle =
                  results[variantId]?.title || 'No title found';
                const germlineClassification =
                  results[variantId]?.germline_classification || {};
                const description =
                  germlineClassification.description || 'No description found';
                variants.push(`Title: ${variantTitle} - ${description}`);
              });

              // Update modal state with variants
              setClinVarResults(variants);
              setSnack({
                status: true,
                msg: 'ClinVar API call successful',
                severity: 'success'
              });
            })
            .catch(() => {
              setSnack({
                status: true,
                msg: 'Failed to fetch ClinVar summary data',
                severity: 'error'
              });
            });
        }
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'Failed to query ClinVar database',
          severity: 'error'
        });
      });
  };

  // ----- NEW: Fetch ClinVar data if genotype.id contains 'NCBIGene:' -----
  useEffect(() => {
    if (open && genotype?.id?.includes('NCBIGene:')) {
      fetchClinVarData(genotype.id);
    } else {
      // Clear out old results if the modal is re-opened for a different genotype
      setClinVarResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, genotype]);

  const handleSaveClick = () => {
    onSave({
      ...genotype,
      clinicallyConfirmed,
      patientReported,
      isPrivate,
      displayOnPedigree,
      dateRecorded,
      recordedBy,
      carrierStatus
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          height: 700, // Fixed height
          overflowY: 'auto', // Scrollable if content exceeds height
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1
        }}
      >
        {/* Top bar: similar to PhenotypeModal */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            backgroundColor: 'secondary.main',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4
          }}
        >
          <Box />
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}
          >
            Edit Genotype
          </Typography>
        </Box>

        {/* Main content area */}
        <Box sx={{ p: 2, pt: 3 }}>
          {/* Basic Genotype info */}
          <Box
            sx={{
              padding: 2,
              borderRadius: 2,
              backgroundColor: '#f9f9f9',
              boxShadow: 1,
              mb: 2
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Symbol:
            </Typography>
            <Typography variant="body1" colour="text.secondary" sx={{ mb: 2 }}>
              {genotype?.name || 'N/A'}
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              ID:
            </Typography>
            <Typography variant="body1" colour="text.secondary" sx={{ mb: 2 }}>
              {genotype?.id || 'N/A'}
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Gene Location:
            </Typography>
            <Typography variant="body1" colour="text.secondary" sx={{ mb: 2 }}>
              {genotype?.location || 'N/A'}
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Definition:
            </Typography>
            <Typography variant="body1" colour="text.secondary">
              {genotype?.definition || 'N/A'}
            </Typography>

            {/* ----- NEW: Display ClinVar results if any ----- */}

            <br></br>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              ClinVar Variants:
            </Typography>
            {clinVarResults.map((result, idx) => (
              <Typography key={idx} variant="body1">
                {result}
              </Typography>
            ))}
          </Box>

          {/* Date / Recorded By */}
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Box sx={{ flex: 1 }}>
              <TextField
                type="date"
                label="Date Recorded"
                value={dateRecorded}
                onChange={(e) => setDateRecorded(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                label="Recorded By"
                value={recordedBy}
                onChange={(e) => setRecordedBy(e.target.value)}
                fullWidth
              />
            </Box>
          </Box>

          {/* Switches */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              mb: 2
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Clinically Confirmed</Typography>
              <Switch
                checked={clinicallyConfirmed}
                onChange={() => setClinicallyConfirmed((prev) => !prev)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Patient Reported</Typography>
              <Switch
                checked={patientReported}
                onChange={() => setPatientReported((prev) => !prev)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Private</Typography>
              <Switch
                checked={isPrivate}
                onChange={() => setIsPrivate((prev) => !prev)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Display on Pedigree</Typography>
              <Switch
                checked={displayOnPedigree}
                onChange={() => setDisplayOnPedigree((prev) => !prev)}
              />
            </Box>
          </Box>

          {/* Carrier Status */}
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="carrier-status-label">Carrier Status</InputLabel>
              <Select
                labelId="carrier-status-label"
                value={carrierStatus}
                label="Carrier Status"
                onChange={(e) => setCarrierStatus(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Carrier">Carrier</MenuItem>
                <MenuItem value="Affected">Affected</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Bottom action buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="outlined" colour="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              colour="secondary"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

// ------------------ Main GenotypesMenu Component ------------------ //
const GenotypesMenu = ({ data, modificationHistoryHandle }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(HPO_CONSTANTS['initialPage']);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGenotype, setSelectedGenotype] = useState(null);

  const { setSnack } = useContext(SnackContext);
  const { content } = useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const t = useAutoTranslation();

  const user_id = useMemo(() => user?.user_id || user?._id, [user]);
  const familyMemberId = useMemo(() => data?.data?.familyMemberId, [data]);
  const proBandId = useMemo(() => data?.data?.proBandId, [data]);

  // Fetch gene data from HPO service based on searchTerm
  const fetchDataByGenes = async (currentPage) => {
    if (searchTerm.length >= 3) {
      const url = getHPOSearchURL(
        searchTerm,
        currentPage,
        HPO_CONSTANTS.category['gene']
      );
      try {
        const response = await axios.get(url);
        if (isError) setIsError(false);

        const { results = [], totalCount: count } = response.data || {};
        const newOptions = results.map((d) => ({
          id: d.id,
          name: d.name
        }));

        if (currentPage === HPO_CONSTANTS['initialPage']) {
          setOptions(newOptions);
        } else {
          setOptions((preOption) => [...preOption, ...newOptions]);
        }
        setTotalCount(count);
        setPage(currentPage);
      } catch (err) {
        setIsError(true);
        console.error('Error fetching genes:', err);
      }
    } else {
      setOptions([]);
    }
  };

  // Fetch saved genotypes from backend
  const getGenotypes = () => {
    const id = proBandId ?? familyMemberId;
    if (!id || !user_id) return;

    axios
      .get(
        APICONSTANTS.getGenotypesById
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          params: { isProband: Boolean(proBandId) }
        }
      )
      .then(({ data }) => {
        const { genotypes = [] } = data || {};
        setSelectedItems(genotypes);
      })
      .catch((err) => {
        console.error('Error fetching genotypes:', err);
      });
  };

  useEffect(() => {
    getGenotypes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchDataByGenes(HPO_CONSTANTS['initialPage']);
    // eslint-disable-next-line
  }, [searchTerm]);

  // Add newly selected genes from Autocomplete to selectedItems
  const handleSelect = (event, value) => {
    if (value) {
      // Remove duplicates
      const uniqueData = value.reduce((acc, curr) => {
        const existingItem = acc.find((item) => item.id === curr.id);
        if (!existingItem) {
          acc.push(curr);
        }
        return acc;
      }, []);

      const merged = [...selectedItems];
      uniqueData.forEach((newItem) => {
        if (!merged.some((m) => m.id === newItem.id)) {
          merged.push({
            ...newItem,
            clinicallyConfirmed: false,
            patientReported: false,
            isPrivate: false,
            displayOnPedigree: false,
            dateRecorded: new Date().toISOString().split('T')[0],
            recordedBy: `${user.firstName} ${user.lastName}`,
            carrierStatus: ''
          });
        }
      });
      setSelectedItems(merged);
      setSearchTerm('');
    }
  };

  // Optionally fetch more info for a genotype from MyGene.info
  const fetchGeneInfo = async (geneName) => {
    try {
      const response = await axios.get('https://mygene.info/v3/query', {
        params: {
          q: geneName,
          fields: 'name,symbol,summary,map_location'
        }
      });
      const hits = response?.data?.hits || [];
      if (hits.length > 0) {
        const { symbol, name, summary, map_location } = hits[0];
        return {
          symbol,
          name,
          definition: summary,
          location: map_location
        };
      }
      return null;
    } catch (error) {
      console.error('Error fetching gene info from MyGene API:', error);
      return null;
    }
  };

  // On tile click, get extended info & open modal
  const handleTileClick = async (genotype) => {
    const myGeneInfo = await fetchGeneInfo(genotype.name);
    if (myGeneInfo) {
      genotype.symbol = myGeneInfo.symbol || genotype.symbol;
      genotype.definition = myGeneInfo.definition || genotype.definition;
      genotype.location = myGeneInfo.location || genotype.location;
    }
    setSelectedGenotype(genotype);
    setModalOpen(true);
  };

  // Save changes from modal
  const handleModalSave = (updatedFields) => {
    const updatedGenotype = { ...selectedGenotype, ...updatedFields };
    // Update state immediately
    setSelectedItems((prev) =>
      prev.map((item) =>
        item.id === updatedGenotype.id ? updatedGenotype : item
      )
    );

    // Sync to backend
    const id = proBandId ?? familyMemberId;
    axios
      .put(
        APICONSTANTS.updateGenotype
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          genotype: updatedGenotype,
          isProband: Boolean(proBandId)
        }
      )
      .then((response) => {
        if (response.data.success) {
          setSnack({
            status: true,
            msg: 'Genotype updated successfully!',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: response.data.message || 'Error updating genotype',
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        console.error('Error updating genotype:', err);
        setSnack({
          status: true,
          msg: 'Error updating genotype',
          severity: 'error'
        });
      })
      .finally(() => {
        setModalOpen(false);
      });
  };

  // POST all selectedItems to backend
  const onSave = () => {
    const id = proBandId ?? familyMemberId;
    if (!id || !user_id) {
      return setSnack({
        status: true,
        msg: 'Kindly save pedigree first!',
        severity: 'error'
      });
    }

    setLoading(true);
    const payload = {
      familyId: user.familyId,
      familyMemberId: id,
      user_id: user_id,
      isProband: Boolean(proBandId),
      genotypes: selectedItems
    };

    axios
      .post(APICONSTANTS.genotypes, payload)
      .then((res) => {
        if (res.data?.success) {
          setSnack({
            status: true,
            msg: 'Genotypes saved successfully',
            severity: 'success'
          });
          // Refresh from backend in case anything changed
          getGenotypes();
        } else {
          setSnack({
            status: true,
            msg: res.data.message || 'Error saving genotypes',
            severity: 'error'
          });
        }
      })
      .catch((err) =>
        setSnack({
          status: true,
          msg: err.response?.data?.error || 'An error has occurred!',
          severity: 'error'
        })
      )
      .finally(() => setLoading(false));
  };

  // Infinite scroll for the Autocomplete
  const handleScroll = (event) => {
    const target = event.target;
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      options.length < totalCount
    ) {
      const newCurrentPage = page + 1;
      fetchDataByGenes(newCurrentPage);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {isError && (
          <Grid item xs={12}>
            <Alert severity="warning">
              {t(
                'The HPO service is unreachable and may be offline. Please also check your Internet connection. You will not be able to search HPO until the connection is restored.'
              )}
            </Alert>
          </Grid>
        )}

        {/* Gene Autocomplete field */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            clearOnBlur={false}
            id="tags-outlined"
            options={options}
            value={[]}
            getOptionLabel={(option) => option.name}
            onChange={handleSelect}
            ListboxProps={{ onScroll: handleScroll }}
            filterOptions={(options) => options} // no built-in filtering
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search for Genes"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            )}
          />
        </Grid>

        {/* Save & History buttons */}
        <Grid item xs={12}>
          <Button
            endIcon={loading ? <CircularProgress size={15} /> : null}
            variant="contained"
            color="secondary"
            onClick={onSave}
            disabled={loading}
          >
            Save
          </Button>
          <IconButton
            sx={{ marginLeft: '10px' }}
            onClick={() =>
              modificationHistoryHandle(CONSTANTS.auditLogType['genotypes'])
            }
          >
            <HistoryIcon />
          </IconButton>
        </Grid>

        {/* Render already selected/saved genotypes as tiles */}
        {selectedItems.map((item) => (
          <Grid key={item.id} item xs={12}>
            <Box
              sx={{
                border: '1px solid #ccc',
                padding: '16px',
                borderRadius: '8px',
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}
              onClick={() => handleTileClick(item)}
            >
              <Typography variant="body1">{item.name || 'N/A'}</Typography>
              <Typography variant="body2">{item.id}</Typography>
              {item.clinicallyConfirmed && (
                <Typography variant="caption" colour="success.main">
                  Clinically Confirmed
                </Typography>
              )}
            </Box>
          </Grid>
        ))}

        {/* Modal for editing genotype details (with integrated ClinVar code) */}
        {selectedGenotype && (
          <GenotypeModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            genotype={selectedGenotype}
            onSave={handleModalSave}
            user={user}
          />
        )}
      </Grid>
    </>
  );
};

export default GenotypesMenu;
