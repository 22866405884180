import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import TextInput from 'src/components/UI/input/TextInput';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, FormControl } from '@mui/material';
import RadioInput from 'src/components/UI/input/RadioInput';
import ColorPicker from 'src/components/UI/input/ColorPicker';
import FormLabel from 'src/components/UI/input/CustomFormLabel';
import SelectInput from 'src/components/UI/input/SelectInput';
import { CONSTANTS } from 'src/components/questionnaire/Constants';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: 'end',
    marginTop: 5
  }
}));

const EditDiagnoseModal = ({
  handleClose,
  onSubmit,
  formValues: initialFormValues
}) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(initialFormValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormValues(initialFormValues);
  }, [initialFormValues]);

  const handleSave = async () => {
    setLoading(true);
    try {
      await onSubmit(formValues);
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
    }
  };

  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <form className="user-form">
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <TextInput
            label={t('Disease Name')}
            name="name"
            value={formValues?.name}
            disabled
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            label={t('Pedigree Display Name')}
            name="alias"
            placeholder={t('Enter display name')}
            value={formValues?.alias}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <FormLabel>{t('Select Colour')}</FormLabel>
          <ColorPicker color={formValues?.color} onChange={handleChange} />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label={t('Diagnosis Age')}
            name="age"
            value={formValues?.age}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="date"
            label={t('Date Reported')}
            name="dateReported"
            value={formValues?.dateReported}
            onChange={(date) =>
              setFormValues((prev) => ({ ...prev, dateReported: date }))
            }
            disableFuture
          />
        </Grid>

        <Grid item xs={12} id="top">
          <FormControl component="fieldset">
            <Grid container spacing={1}>
              <Grid item>
                <FormLabel>Mode of Inheritance </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  fullWidth={true}
                  variant="outlined"
                  name="inheritanceMode"
                  options={CONSTANTS.inheritanceModesOptions}
                  value={formValues.inheritanceMode || ''}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <RadioInput
            label={t('Clinically Confirmed')}
            name="clinicallyConfirmed"
            onChange={handleChange}
            options={options}
            value={formValues?.clinicallyConfirmed}
            row
          ></RadioInput>
        </Grid>
        <Grid item xs={6}>
          <RadioInput
            label={t('Patient Reported')}
            name="isPatientReported"
            onChange={handleChange}
            options={options}
            value={formValues?.isPatientReported}
            row
          ></RadioInput>
        </Grid>
        <Grid item xs={6}>
          <RadioInput
            label={t('Private')}
            name="isPrivate"
            value={formValues?.isPrivate}
            onChange={handleChange}
            options={options}
            row
          ></RadioInput>
        </Grid>
        <Grid item xs={6}>
          <RadioInput
            label={t('Display on Pedigree')}
            name="displayOnPedigree"
            value={formValues?.displayOnPedigree}
            onChange={handleChange}
            options={options}
            row
          ></RadioInput>
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSave}
            disabled={loading}
            endIcon={loading ? <CircularProgress size={15} /> : undefined}
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditDiagnoseModal;
