import React from 'react';
import CustomDialog from '../UI/dialog/CustomDialog';
import axios from 'src/axios';
import { APICONSTANTS } from '../Constants';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';

const NodeReassignMenu = ({
  user,
  deepCopy,
  setDeepCopy,
  setDataset,
  open,
  handleClose,
  setSnack
}) => {
  const unknowNodes = deepCopy.filter((p) => !p.spouse && p.top_level);

  const [selectedNode, setSelectedNode] = React.useState(null);

  const handleChange = (event) => {
    setSelectedNode(event.target.value);
  };

  const onSumbit = () => {
    const person = unknowNodes.find((p) => p.upn === selectedNode);
    if (selectedNode) {
      axios
        .put(
          APICONSTANTS.reassignPedigreeMember.replace(':user_id', user._id),
          {
            pos: open.pid,
            node: person
          }
        )
        .then((res) => {
          let dataset = deepCopy;
          let member = res.data.replaced_member;
          if (member) {
            if (Number(selectedNode) > 0) {
              dataset = deepCopy.filter((p) => p.pid !== person.pid);
            }
            let idx = dataset.findIndex((p) => p.pid === open.pid);
            dataset[idx] = member;
            setDeepCopy(() => {
              return JSON.parse(JSON.stringify(dataset));
            });
            setDataset(dataset);
            handleClose();
          }
        })
        .catch((err) =>
          setSnack({
            status: true,
            msg: 'Error: Unable to Assign',
            severity: 'error'
          })
        );
    }
  };

  return (
    <CustomDialog
      open={open}
      title={'Assign member to selected position'}
      handleClose={handleClose}
      minHeight={100}
      distanceTop={100}
      allowBackgroundClose={true}
    >
      <Box>
        <RadioGroup onChange={handleChange}>
          {unknowNodes.map((node) => {
            return (
              <FormControlLabel
                key={node.upn}
                value={node.upn}
                control={<Radio />}
                label={`UPN : ${node.upn}`}
              />
            );
          })}
          <FormControlLabel
            value={-1}
            control={<Radio />}
            label="Assign a new person"
          />
        </RadioGroup>
        <Box display={'flex'} gap={3} mt={10} justifyContent={'end'}>
          <Button variant="outlined" onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="contained"
            disabled={!selectedNode}
            onClick={onSumbit}
          >
            Update
          </Button>
        </Box>
      </Box>
    </CustomDialog>
  );
};

export default NodeReassignMenu;
