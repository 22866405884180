import React, { useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

export default function UnhideModal(props) {
  const { hideDataset, unHideHandle } = props;
  const [newDataset, setNewDataset] = useState(hideDataset);

  const handleChange = (pid) => {
    const data = newDataset.map((dataset) => {
      if (dataset.pid === pid) {
        return {
          ...dataset,
          isHide: !dataset.isHide
        };
      } else return { ...dataset };
    });

    setNewDataset(data);
  };

  let updatedDatasetID = useMemo(() => {
    return newDataset
      .filter((data) => data.isHide === false)
      .map((data) => data.pid);
  }, [newDataset]);

  let allHiddenNodeIds = useMemo(() => {
    return hideDataset.map((data) => data.pid);
  }, [hideDataset]);

  const isHide = useMemo(() => {
    return newDataset?.some((data) => !data.isHide);
  }, [newDataset]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={10}>
        {newDataset.map((data, index) => {
          return (
            <Box border={1} borderColor="grey.800" sx={{ py: 1 }} key={index}>
              <Grid item container alignItems="center">
                <Grid item xs={1}>
                  <Checkbox
                    checked={!data.isHide}
                    onChange={() => handleChange(data.pid)}
                    size="small"
                    name="name"
                  />
                </Grid>

                <Grid item xs={3}>
                  <div>
                    <span style={{ opacity: 0.7 }}>Upn:</span>{' '}
                    {data.proBandId ? '1' : data.upn}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <span style={{ opacity: 0.7 }}>Name:</span> {data.firstName}
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div>
                    <span style={{ opacity: 0.7 }}>Age:</span>
                    {data.age}
                  </div>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Grid>

      <Grid item xs={12} container spacing={2}>
        <Grid item>
          <Button
            onClick={() => unHideHandle(updatedDatasetID)}
            variant="contained"
            color="secondary"
            disabled={!isHide}
          >
            Unhide
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => unHideHandle(allHiddenNodeIds)}
            color="primary"
            variant="outlined"
          >
            Unhide All
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
