import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { FORM_TYPES } from 'src/components/form-builder/CreateQuestionnaire/constants/formTypes';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import FormFields from 'src/components/custom-form/UserCustomFormFields.jsx';
import { organizeFormGroups } from 'src/components/form-builder/CreateQuestionnaire/components/Preview/Preview';
import HeaderTitle from 'src/components/UI/header/HeaderTitle';

const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 1px 5px rgb(0 74 116 / 15%)',
    maxWidth: '900px',
    padding: '24px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#fff'
  }
}));

export default function ViewFormResponseModal({ readOnly, selectedResponse }) {
  const classes = useStyles();
  const { form: schema, responses = {} } = selectedResponse;

  const [initialFormikValues, setInitialFormikValues] = useState(
    Object.keys(responses).length ? responses : {}
  );
  const [validationSchema, setValidationSchema] = useState(null);
  const [specificConditon, setSpecificConditon] = useState('');

  //setting render condition of starting node = true
  schema.config.schema[schema.config.startingGroupId].renderCondition = true;

  useEffect(() => {
    const yupValidationSchema = {};
    const initialValues = initialFormikValues;
    Object.keys(schema.config.schema).map((schemaId) => {
      let data = schema.config.schema[schemaId];
      (data.condition
        ? data.renderCondition === undefined
          ? true
          : data.renderCondition
        : true) &&
        data.formElements.map((element) => {
          //encoding label and replacing period  to resolve conflicting with formik
          let key = encodeURIComponent(element.label.replaceAll('.', '_'));

          if (element.isRequired) {
            if (
              element.type === FORM_TYPES.MULTIPLE_CHOICE ||
              element.type === FORM_TYPES.LINEAR_SCALE
            ) {
              yupValidationSchema[key] = yup
                .string()
                .required(`${element.label} is required`)
                .oneOf(element.options);
            } else if (element.type === FORM_TYPES.SHORT_TEXT) {
              yupValidationSchema[key] = yup
                .array()
                .min(1)
                .of(yup.string().required())
                .required();
            } else if (element.type === FORM_TYPES.CHECKBOX_INPUT) {
              yupValidationSchema[key] = yup
                .array()
                .of(
                  yup.object().shape({
                    checked: yup.boolean()
                  })
                )
                .test({
                  message: `${element.label} is required`,
                  test: (val) => !val.every((val) => val.checked === false)
                });
            } else {
              yupValidationSchema[key] = yup
                .string()
                .required(`${element.label} is required`);
            }
          }

          if (element.type === FORM_TYPES.CHECKBOX_INPUT) {
            const values = [];
            if (!initialValues[[key]]) {
              for (const option of element.options) {
                values.push({ label: option, checked: false, value: option });
              }
              if (element.isIdentifier) {
                values.push({ label: 'Other', checked: false, value: '' });
              }
            }
            initialValues[[key]] = initialValues[[key]] || [...values];
          } else if (element.type === FORM_TYPES.SHORT_TEXT) {
            initialValues[[key]] = initialValues[[key]] || [''];
          } else {
            initialValues[[key]] = initialValues[[key]] || '';
          }
        });
    });

    setInitialFormikValues(initialValues);
    setValidationSchema(yup.object(yupValidationSchema));
  }, [specificConditon]);

  const formik = useFormik({
    initialValues: initialFormikValues,
    validationSchema: validationSchema
  });

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    {
      Object.keys(schema.config.schema).map((schemaId) => {
        let formData = schema.config.schema[schemaId];

        const key = formData.condition?.condition.split('| ')[1];
        const value = formData.condition?.value;
        const formikValue = formik.values[key];

        const previousFormRenderCondition =
          formData.previousConnections
            .map((id) => {
              return schema.config.schema[id]?.renderCondition;
            })
            .indexOf(true) > -1
            ? true
            : false;

        function arrayEquals(a, b) {
          // received b is an array of object , grabbing values
          const c = [];
          for (const item of b) {
            if (item.checked) {
              c.push(item.value);
            }
          }
          c.sort();
          return (
            Array.isArray(a) &&
            Array.isArray(c) &&
            a.length === c.length &&
            a.every((val, index) => val === c[index])
          );
        }

        let condition;
        if (
          key &&
          value &&
          Array.isArray(value) &&
          Array.isArray(formikValue)
        ) {
          condition =
            arrayEquals(value.sort(), formikValue.sort()) &&
            previousFormRenderCondition;
        } else {
          if (key)
            condition =
              formik.values[encodeURIComponent(key)] === value &&
              previousFormRenderCondition;
        }

        if (formData.condition) {
          setSpecificConditon(condition === undefined ? true : condition);
          schema.config.schema[schemaId].renderCondition =
            condition === undefined ? true : condition;
        } else {
          setSpecificConditon(false);
        }
      });
    }

    const groups = organizeFormGroups(schema.config);

    setGroups(groups);
  }, [formik.values]);

  // This will initialise form with initialFormikValues
  useEffect(() => {
    formik.setValues(initialFormikValues);
  }, [initialFormikValues]);

  return (
    <>
      <div>
        {groups &&
          groups.map((group, index) => {
            if (group[0].data.renderCondition) {
              return (
                <div
                  key={index}
                  className={classes.container}
                  style={{ marginBlock: 20 }}
                >
                  {group.map((form, index) => {
                    if (form.type === 'form') {
                      return (
                        form &&
                        (form.data.condition
                          ? form.data.renderCondition
                          : true) && (
                          <div key={form.data.id}>
                            <HeaderTitle text={form.data.title} />
                            <Typography gutterBottom>
                              {form.data.description}
                            </Typography>
                            <FormikProvider value={formik}>
                              {form.data.formElements.map((element, index) => {
                                return (
                                  <FormFields
                                    element={element}
                                    key={index}
                                    formik={formik}
                                    readOnly={readOnly}
                                  />
                                );
                              })}
                            </FormikProvider>
                          </div>
                        )
                      );
                    } else {
                      return (
                        form &&
                        (form.data.condition
                          ? form.data.renderCondition
                          : true) && (
                          <div key={form.data.id}>
                            <FormikProvider value={formik}>
                              {form.data.formElements.map((element, index) => {
                                return (
                                  <FormFields
                                    element={element}
                                    key={index}
                                    formik={formik}
                                    readOnly={readOnly}
                                  />
                                );
                              })}
                            </FormikProvider>
                          </div>
                        )
                      );
                    }
                  })}
                </div>
              );
            }
          })}
      </div>
    </>
  );
}
