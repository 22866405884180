import React, { useState, useContext, useEffect } from 'react';
import {
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
  FormControl
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormLabel from 'src/components/UI/input/CustomFormLabel';
import SelectInput from 'src/components/UI/input/SelectInput';
import { organizeFormGroups } from 'src/components/form-builder/CreateQuestionnaire/components/Preview/Preview';
import { FormData, EditMode } from '../../CreateQuestionnaire';
import axios from 'src/axios';
import { APICONSTANTS } from 'src/components/Constants';
import { SnackContext } from 'src/store/ContextStore';

// Currently assuming destination fields for only consent form
const destinationFields = [
  { label: 'Consent Type', value: 'consentType' },
  { label: 'Consent Date', value: 'consentDate' },
  { label: 'Response', value: 'response' }
];
const clinicalRecordOptions = [{ label: 'Consent', value: 'consent' }];
const DEFAULT_FORM_TYPE = 'consent';

const MappingUI = ({ data }) => {
  const [formType, setFormType] = useState(DEFAULT_FORM_TYPE);
  const [mappings, setMappings] = useState([]);
  const [sourceFields, setSourcefields] = useState([]);
  const { setSnack } = React.useContext(SnackContext);
  const edit = useContext(EditMode);

  const FORM_DATA = useContext(FormData);

  useEffect(() => {
    let formData;
    if (Object.keys(FORM_DATA).length > 0) {
      formData = FORM_DATA[0];
    } else {
      formData = data.config;
    }

    const groups = organizeFormGroups(formData);

    let sourceFields = [];
    groups.map((group) => {
      group.map((form) => {
        const { formElements } = form.data;

        formElements.map((element) => {
          sourceFields.push({
            label: element.label,
            value: element.id
          });
        });
      });
    });

    // Fields for currently selected forms
    setSourcefields(sourceFields);
  }, [FORM_DATA]);

  const fetchMappings = async (schemaId) => {
    if (!schemaId) return;
    try {
      // Fetch all mappings for currently selected form.
      const resp = await axios.get(
        APICONSTANTS.formBuilderSchemasMappings.replace(':_id', schemaId)
      );

      let mappings = resp.data.mappings[formType] || [];

      setMappings(mappings);
    } catch (error) {
      console.error('Error fetching mappings:', error);
    }
  };

  useEffect(() => {
    fetchMappings(edit.schemaId);
  }, [edit]);

  const isAddMappingDisabled = () => {
    return mappings.some((mapping) => !mapping.source || !mapping.destination);
  };

  const isSaveConfigDisabled = () => {
    return mappings.some((mapping) => !mapping.source || !mapping.destination);
  };

  const isDuplicateMapping = (newValue, field, currentIndex) => {
    return mappings.some(
      (mapping, index) =>
        mapping[field] === newValue &&
        mapping[field] !== '' &&
        index !== currentIndex
    );
  };

  const handleAddMapping = () => {
    setMappings([...mappings, { source: '', destination: '' }]);
  };

  const handleRemoveMapping = (index) => {
    const newMappings = [...mappings];
    newMappings.splice(index, 1);
    setMappings(newMappings);
  };

  const handleChange = (index, field, value) => {
    if (isDuplicateMapping(value, field, index)) {
      setSnack({
        status: true,
        msg: `${
          field === 'source' ? 'Source' : 'Destination'
        } value already mapped.`,
        severity: 'warning'
      });
      return;
    }
    const newMappings = [...mappings];
    newMappings[index][field] = value;
    setMappings(newMappings);
  };

  const handleSaveConfig = () => {
    if (!edit.schemaId) return;

    axios
      .post(
        APICONSTANTS.formBuilderSchemasMappings.replace(':_id', edit.schemaId),
        {
          [formType]: mappings
        }
      )
      .then((res) => {
        setSnack({
          status: true,
          msg: 'Mappings saved successfully',
          severity: 'success'
        });
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: 'An error has occurred!',
          severity: 'error'
        });
      });
  };

  return (
    <Box p={4}>
      <Grid container spacing={3}>
        {/* Header */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Configure Field Mapping
          </Typography>
        </Grid>

        {/* Select Clinical Record Mapping Type */}
        <Grid item xs={12} md={6}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel style={{ fontWeight: 'bold' }}>
              Select clinical record mapping type
            </FormLabel>
            <SelectInput
              fullWidth
              variant="outlined"
              options={clinicalRecordOptions}
              value={formType}
              onChange={(e) => setFormType(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Field Mapping Section */}
      <Grid container spacing={3} mt={4}>
        {mappings && mappings.length > 0 ? (
          <>
            {/* Headers */}
            <Grid item xs={4}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Source Columns
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Destination Fields
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Actions
              </Typography>
            </Grid>
          </>
        ) : (
          <></>
        )}

        {/* Mapping Rows */}
        {mappings.map((mapping, index) => (
          <Grid
            container
            spacing={3}
            key={index}
            alignItems="center"
            style={{ marginBottom: '1rem', marginLeft: 0 }}
          >
            {/* Source */}
            <Grid item xs={4}>
              <SelectInput
                fullWidth
                label="Source"
                variant="outlined"
                options={sourceFields}
                value={mapping.source}
                onChange={(e) => handleChange(index, 'source', e.target.value)}
              />
            </Grid>
            <Grid item xs={1} textAlign="center">
              <ArrowForwardIcon />
            </Grid>

            {/* Destination */}
            <Grid item xs={4}>
              <SelectInput
                fullWidth
                label="Destination"
                variant="outlined"
                options={destinationFields}
                value={mapping.destination}
                onChange={(e) =>
                  handleChange(index, 'destination', e.target.value)
                }
              />
            </Grid>

            {/* Actions */}
            <Grid item xs={3} textAlign="center">
              <IconButton
                color="error"
                onClick={() => handleRemoveMapping(index)}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        {/* Add Mapping and Save Configuration Buttons */}
        <Grid item xs={12} textAlign="right">
          <Button
            variant="contained"
            onClick={handleAddMapping}
            style={{ marginRight: '1rem' }}
            disabled={isAddMappingDisabled()}
          >
            Add Mapping
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveConfig}
            disabled={isSaveConfigDisabled()}
          >
            Save Configuration
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MappingUI;
