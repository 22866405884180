// Libraries
import React, { useState, useContext } from 'react';

// Styles
import styles from './FormBuilderPage.module.css';

// Components
import FormBuilderTool from '../FormBuilderTool';
import Preview from '../Preview/Preview';

import TabPanel, { tabStyles } from 'src/components/UI/appbar/TabPanel';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'src/axios';
import { Button } from '@mui/material';

import { EditMode, FormData } from '../../CreateQuestionnaire';
import { APICONSTANTS, CONSTANTS } from 'src/components/Constants';
import { useHistory } from 'react-router-dom';
import { SnackContext, ThemeContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import Mapping from '../Mapping/Mapping';

function FormBuilderPage() {
  const { theme } = React.useContext(ThemeContext);
  const classes = tabStyles(theme);
  const formData = useContext(FormData);
  const edit = useContext(EditMode);
  const history = useHistory();
  const { setSnack } = React.useContext(SnackContext);

  const saveConfig = () => {
    axios
      .post(APICONSTANTS.formBuilderSchemas, { formData: formData[0] })
      .then((res) => {
        setSnack({
          status: true,
          msg: 'Questionnaire created',
          severity: 'success'
        });
        if (res.data.success) history.push(CONSTANTS.formCreationPath);
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'An error has occurred!',
          severity: 'error'
        });
      });
  };

  const updateConfig = (id) => {
    axios
      .put(APICONSTANTS.formBuilderSchemas + '/' + id, {
        formData: formData[0]
      })
      .then((res) => {
        setSnack({
          status: true,
          msg: 'Questionnaire updated successfully',
          severity: 'success'
        });
        if (res.data.success) history.push(CONSTANTS.formCreationPath);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setSnack({
            status: true,
            msg: 'Unauthorized !',
            severity: 'error'
          });
        } else {
          setSnack({
            status: true,
            msg: 'An error has occurred!',
            severity: 'error'
          });
        }
      });
  };

  const TAB_PANEL_COMPONENTS = [
    {
      label: 'Edit',
      component: <FormBuilderTool />
    },
    {
      label: 'Preview',
      component: <Preview />
    },
    {
      label: 'Mapping',
      component: <Mapping />
    }
  ];

  const [value, setValue] = useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const t = useAutoTranslation();

  return (
    <div className={styles.FormBuilderPage}>
      <div style={{ width: '90vw', margin: 'auto' }}>
        <p>{t(`Add form elements to a section to group them together.`)}</p>
        <p>
          {t(
            ` Conditions can be added to sections to make them visible only when conditions are met.`
          )}
        </p>
        <div style={{ marginBlock: '10px' }}>
          <div style={{ position: 'relative' }}>
            <AppBar position="static" className={classes.colorPrimary}>
              <Tabs
                value={value}
                onChange={handleChange}
                classes={{
                  indicator: classes.indicator
                }}
              >
                {TAB_PANEL_COMPONENTS.map((el, index) => (
                  <Tab key={index} label={t(el.label)} />
                ))}
              </Tabs>
            </AppBar>
            <div style={{ position: 'absolute', top: 5, right: 10 }}>
              {edit.status ? (
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  // edit.schemaId not equal to schemaId from formData schema
                  onClick={() => updateConfig(edit.schemaId)}
                >
                  {t(`Update`)}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  onClick={saveConfig}
                >
                  {t(`Save`)}
                </Button>
              )}
            </div>
          </div>
          <TabPanel value={value} index={value}>
            <div className={styles.FormBuilderContainer}>
              {TAB_PANEL_COMPONENTS[value].component}
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

export default FormBuilderPage;
