import { Box, Grow, List, ListItemButton } from '@mui/material';
import React from 'react';
import { SnackContext, ThemeContext } from 'src/store/ContextStore';
import { generateNewUser } from '../pedigreeUtil';
import { adjacent_nodes, getSpouse } from '../Menu/pedigree-utils';
import axios from 'src/axios';
import { APICONSTANTS } from 'src/components/Constants';

export default function LinkMenu({
  node,
  deepCopy,
  setDataset,
  setDeepCopy,
  handleClose,
  rootConfig
}) {
  const { theme } = React.useContext(ThemeContext);
  const { setSnack } = React.useContext(SnackContext);
  const { d, link } = React.useMemo(
    () => ({ d: node.d, link: node.link }),
    [node]
  );

  const userId =
    d?.target?.data.user_id ||
    d?.mother?.data.user_id ||
    d?.father?.data.user_id;

  const autoSaveUpdate = ({
    removals = [],
    updationRequired = [],
    addons = []
  }) => {
    axios
      .post(APICONSTANTS.removeRelationInPedigree.replace(':user_id', userId), {
        removals,
        updationRequired
      })
      .catch((err) =>
        setSnack({
          status: true,
          msg: 'Error: Failed to saved the updates. Try again',
          severity: 'error'
        })
      );
  };

  const removeChildRelation = (d) => {
    const node = deepCopy.find((p) => p.pid === d.target.data.pid);
    const node_spouse = deepCopy.find((p) => p.pid === d.target.data.spouse);

    const updationRequired = [];
    const addons = [];

    const rep_child = generateNewUser();
    rep_child.hidden = true;
    rep_child.not_set = true;
    rep_child.mother = node.mother;
    rep_child.father = node.father;
    deepCopy.push(rep_child);

    if (node_spouse?.mother && node_spouse?.father && !node_spouse?.noparents) {
      node.father = node_spouse.father;
      node.mother = node_spouse.mother;
      node.noparents = 'true';
    } else {
      if (node_spouse) {
        delete node_spouse?.mother;
        delete node_spouse?.father;
        delete node_spouse?.noparents;
        node_spouse.top_level = 'true';
      }
      delete node.mother;
      delete node.father;
      node.top_level = 'true';
    }

    updationRequired.push(node, node_spouse);
    addons.push(rep_child);

    autoSaveUpdate({ updationRequired, addons });

    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(deepCopy));
    });
    setDataset(deepCopy);
  };

  const removeSpouseRelation = (_father, _mother) => {
    const father = deepCopy.find((p) => p.pid === _father?.pid);
    const mother = deepCopy.find((p) => p.pid === _mother?.pid);

    const parents = [mother.pid, father.pid];

    const children = deepCopy.filter(
      (p) =>
        parents.includes(p.mother) && parents.includes(p.father) && !p.not_set
    );

    let updationRequired = [];

    let removals = deepCopy
      .filter(
        (p) =>
          parents.includes(p.mother) && parents.includes(p.father) && p.not_set
      )
      .map((p) => p.pid);

    if (father.deleted) removals.push(father.pid);
    if (mother.deleted) removals.push(mother.pid);

    if (father.spouse === mother.pid) delete father.spouse;
    if (mother.spouse === father.pid) delete mother.spouse;

    const node = rootConfig.find(
      (node) => node?.data?.pid === children[0]?.pid
    );

    const nodesAtsameDepth = adjacent_nodes(rootConfig, node);

    let nh_node = nodesAtsameDepth[0];

    children.map((child) => {
      if (child.spouse) {
        let spouse = getSpouse(deepCopy, child.spouse);
        if (!spouse.noparents) {
          child.mother = spouse.mother;
          child.father = spouse.father;
          child.noparents = 'true';
        }
      }

      if (nh_node) {
        child.mother = nh_node.mother;
        child.father = nh_node.father;
        child.noparents = 'true';
      } else {
        delete child.mother;
        delete child.father;
        delete child.noparents;
        child.top_level = 'true';
      }
    });

    if (!mother.deleted) updationRequired.push(mother);
    if (!father.deleted) updationRequired.push(father);
    updationRequired.push(...children);

    autoSaveUpdate({ removals, updationRequired });

    deepCopy = deepCopy.filter((p) => !removals.includes(p.pid));

    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(deepCopy));
    });
    setDataset(deepCopy);
  };

  const removeRelation = () => {
    if (d && d?.target) {
      removeChildRelation(d);
    } else if (d && d?.mother && d.father)
      removeSpouseRelation(d.father.data, d.mother.data);
    handleClose();
  };

  return (
    <>
      {node && (
        <Grow
          in={node}
          style={{
            transformOrigin: 'top bottom'
          }}
        >
          <Box
            width={153}
            sx={{
              bgcolor: theme.appearance.color,
              borderRadius: 2,
              border: '1px #777 solid'
            }}
          >
            <List>
              <ListItemButton onClick={removeRelation}>
                Remove Relation
              </ListItemButton>
              <ListItemButton disabled>Update Relation</ListItemButton>
            </List>
          </Box>
        </Grow>
      )}
    </>
  );
}
