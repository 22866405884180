import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';

import axios from 'src/axios';

import { Box, Button, MenuItem, Select, TextField } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { FormControl } from '@mui/material';
import { formatDate } from 'src/util/util';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackContext, ThemeContext, AppContext } from 'src/store/ContextStore';
import DateInput from '../../../UI/input/DateInput';

import dayjs from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import TextInput from 'src/components/UI/input/TextInput';
import AssociatedNote from 'src/components/UI/input/AssociatedNote';
import { getReferralTags, handleUpload, getDependencies } from './formService';

import UploadFileList from 'src/components/UI/input/UploadFileList';
import ClinicalNoteDeleteModal from '../ClinicalNoteDeleteModal';
import SelectInput from 'src/components/UI/input/SelectInput';

export default function ContactForm({
  handleClose,
  familyMemberId,
  isEditing,
  model,
  staffMembers,
  staffMemberOptions
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const defaultValues = isEditing
    ? { ...model, fileAttachments: [] }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        contactMode: '',
        contactType: 'None', //(Letter, E-mail, Telephone, Video Call +Allow users to add their own values)
        reasonForContact: '', //(Family History, Follow Up, +Allow users to add their own values)
        contactDate: new Date(), //Contact Date (Date Picker)
        contactStatus: 'Scheduled', //Contact Status (Scheduled, Sent / Completed, Did Not Attend, Cancelled)
        contactTime: dayjs(new Date()), //Sort this
        link: '', //Link (Free Text - e.g. for Zoom / Teams link)
        associatedReferral: '', //Associated Referral (Dropdown to Select from Patient Referrals)
        fileAttachments: [], //Make this form
        contactNotes: '',
        showInFamily: false,
        dateRequestedForChecking: new Date(),
        dateApproved: new Date()
      };
  const { theme } = useContext(ThemeContext);
  let [referralOptions, setReferralOptions] = React.useState(['loading...']);

  const { setSnack } = React.useContext(SnackContext);

  const allStaffMembers = React.useMemo(() => {
    return staffMemberOptions.map((options) => options.value);
  }, [staffMemberOptions]);

  const staffMembersMap = React.useMemo(() => {
    return staffMemberOptions.reduce((acc, item) => {
      acc[item.value] = item.label;
      return acc;
    }, {});
  }, [staffMemberOptions]);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const deleteNote = async (e) => {
    setLoading(true);

    const dep = await getDependencies(
      user.familyId,
      model._id,
      theme.dateFormat
    );
    if (dep.length !== 0) {
      setSnack({
        status: true,
        msg: `Associated record exists; check the ${dep[0]}`,
        severity: 'error'
      });
      setLoading(false);

      return;
    }
    axios
      .delete(`/clinical-notes/note`, {
        params: {
          _id: model._id
        }
      })
      .then((res) => {
        setSnack({
          status: true,
          msg: 'Record deleted',
          severity: 'success'
        });
        handleCloseModal();
        handleClose();
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Delete failed! ${err}`,
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [formValues, setFormValues] = React.useState(defaultValues);
  //Make this a controller?
  React.useEffect(() => {
    const fetchData = async () => {
      const referralTags = await getReferralTags(
        theme,
        familyMemberId,
        formValues.familyId
      );
      setReferralOptions(referralTags);
    };
    if (referralOptions != [] && referralOptions[0] == 'loading...')
      fetchData();
  });

  const [contactTypeOptions] = React.useState([
    'Follow Up',
    'Pre-Clinic',
    'Consultation'
  ]);

  const [contactModeOptions] = React.useState([
    'Letter',
    'Email',
    'Telephone',
    'Video Call'
  ]);
  const handleChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const handleFile = (e) => {
    handleUpload(e);
    let fileAttachments = formValues.fileAttachments;
    fileAttachments.push(e.target.files[0].name);
    setFormValues({
      ...formValues,
      fileAttachments: fileAttachments
    });
  };
  //Sure about this! Will be good for show in Family
  const onToggleSwitch = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.checked
    }));
  };

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let requestChecking = null;
    let staffMember = null;

    if (formValues.requestChecking) {
      requestChecking = staffMembers.find(
        (member) => member.userId === formValues.requestChecking
      );
    }

    if (formValues.staffMember && formValues.staffMember.length > 0) {
      staffMember = formValues.staffMember.map((staff) => {
        let existStaffMember = staffMembers.find(
          (member) => member.userId === staff
        );

        return {
          ...existStaffMember
        };
      });
    }

    const parsePayload = {
      ...formValues,
      staffMember: staffMember ? [...staffMember] : [],
      requestChecking: requestChecking ? [requestChecking] : [],
      dateRequestedForChecking:
        requestChecking && formValues.dateRequestedForChecking
          ? formValues.dateRequestedForChecking
          : null,
      dateApproved:
        formValues.approved && formValues.dateApproved
          ? formValues.dateApproved
          : null
    };

    handleClose();
    return axios
      .put(APICONSTANTS.contact, parsePayload)
      .then((res) => {
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  const handleDelete = (e) => {
    let fileAttachments = formValues.fileAttachments;
    const deleted = formValues.fileAttachments[e];

    axios.delete(`${APICONSTANTS.fileAttachment}`, {
      params: {
        fileName: deleted
      }
    });
    fileAttachments = fileAttachments.filter((attachment, i) => i !== e);
    setFormValues((prevValues) => ({
      ...prevValues,
      fileAttachments
    }));
  };

  const reqCheckingOptions = React.useMemo(() => {
    return staffMemberOptions.filter(
      (member) => !formValues.staffMember?.includes(member.value)
    );
  }, [staffMemberOptions, formValues]);

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <ClinicalNoteDeleteModal
        loading={loading}
        open={open}
        handleCloseModal={handleCloseModal}
        onConfirm={deleteNote}
        title="Are you sure you want to delete this Contact ?"
        content="Warning: This action will permanently delete this Contact. Once deleted, this
        information will be permanently removed and cannot be restored."
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Reason For Contact</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="reasonForContact"
              value={formValues.reasonForContact}
              multiline={true}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Contact Mode"
              type="autocomplete"
              name="contactMode"
              value={formValues.contactMode || ''}
              onChange={(e) => {
                const label = e ? e.label : '';
                setFormValues({
                  ...formValues,
                  contactMode: label
                });
              }}
              options={contactModeOptions || []}
              helperText="Please select or enter a user group"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Associated Referral</FormLabel>
          </Grid>
          <AssociatedNote
            name="associatedReferral"
            handleChange={handleChange}
            value={formValues.associatedReferral}
            noteOptions={referralOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Contact Date</FormLabel>
          </Grid>
          <DateInput
            name="contactDate"
            value={
              formValues.contactDate
                ? new Date(formatDate(formValues.contactDate))
                : null
            }
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                contactDate: e
              }))
            }
            variant="outline"
            minDate={isEditing ? new Date(1600, 0, 0) : new Date()}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Contact Time</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={formValues.contactTime}
                onChange={(e) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    contactTime: e
                  }));
                }}
              />
            </LocalizationProvider>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Contact Status</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <FormControl fullWidth>
              <Select
                style={{ width: '100%' }}
                name="contactStatus"
                value={formValues.contactStatus}
                onChange={handleChange}
              >
                {[
                  'Scheduled',
                  'Sent / Completed',
                  'Did Not Attend',
                  'Cancelled'
                ].map((option) => (
                  <MenuItem value={option} key={option} onClick={handleChange}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Contact Type"
              type="autocomplete"
              name="contactType"
              value={formValues.contactType || ''}
              onChange={(value) => {
                setFormValues({
                  ...formValues,
                  contactType: value
                });
              }}
              options={contactTypeOptions || []}
              helperText="Please select or enter a contact type"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Link</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="link"
              value={formValues.link}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Contact Notes</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="contactNotes"
              value={formValues.contactNotes}
              multiline={true}
            />
          </Box>
        </Grid>
        <UploadFileList
          value={formValues.fileAttachments}
          onChange={(value) =>
            setFormValues({
              ...formValues,
              fileAttachments: value
            })
          }
        />

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Show in family
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.showInFamily ?? false}
                onChange={onToggleSwitch}
                name="showInFamily"
              />
            </Grid>
            <Grid item>
              <Typography>{formValues.showInFamily ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Staff Member */}
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput
                  label={'Staff Member'}
                  name="staffMember"
                  type="multiple-select"
                  value={formValues?.staffMember || []}
                  onChange={handleChange}
                  options={allStaffMembers || []}
                  mapper={staffMembersMap || {}}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>

        {/* Request Checking*/}
        {formValues.staffMember && formValues.staffMember.length > 0 && (
          <Grid item xs={12} id="top">
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Request Checking </FormLabel>
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                fullWidth={true}
                variant="outlined"
                name="requestChecking"
                options={reqCheckingOptions}
                value={formValues.requestChecking || ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        )}

        {/*Date Requested Checking*/}
        {formValues.requestChecking && (
          <Grid item xs={12}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Date Requested for Checking</FormLabel>
            </Grid>
            <DateInput
              name="dateRequestedForChecking"
              value={
                formValues.dateRequestedForChecking
                  ? new Date(formatDate(formValues.dateRequestedForChecking))
                  : null
              }
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  dateRequestedForChecking: e
                }))
              }
              variant="outline"
            />
          </Grid>
        )}

        {/*Approved*/}
        {formValues.requestChecking && formValues.dateRequestedForChecking && (
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={6} sm={7}>
              <FormLabel component="label" className="form-label">
                Approved
              </FormLabel>
            </Grid>
            <Grid item container xs={6} sm={3} alignItems="center">
              <Grid item>
                <Switch
                  color="secondary"
                  checked={formValues.approved ?? false}
                  onChange={onToggleSwitch}
                  name="approved"
                />
              </Grid>
              <Grid item>
                <Typography>{formValues.approved ? 'Yes' : 'No'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/*Date Approved*/}
        {formValues.approved && (
          <Grid item xs={12}>
            <Grid sx={{ paddingBlock: 1 }}>
              <FormLabel>Date Approved</FormLabel>
            </Grid>
            <DateInput
              name="dateApproved"
              value={
                formValues.dateApproved
                  ? new Date(formatDate(formValues.dateApproved))
                  : null
              }
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  dateApproved: e
                }))
              }
              variant="outline"
            />
          </Grid>
        )}

        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          intermediateBtnLabel="Delete"
          intermediateAction={isEditing ? handleOpenModal : null}
          handleClose={handleClose}
          addLabel={`Add new Contact Details`}
        />
      </Grid>
    </form>
  );
}
