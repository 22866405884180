import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { SnackContext } from 'src/store/ContextStore';
import axios from 'src/axios';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import { APICONSTANTS, CONSTANTS } from 'src/components/Constants';
import TextInput from 'src/components/UI/input/TextInput';
import { useHistory } from 'react-router-dom';
import { generateNewUser } from '../pedigreeUtil';
import { adjacent_nodes, getSpouse } from './pedigree-utils';

function DeletionMenu({
  node,
  handleClose,
  setDataset,
  setDeepCopy,
  deepCopy,
  rootConfig
}) {
  const { setSnack } = React.useContext(SnackContext);
  const history = useHistory();
  const [confirmFamMemberId, setConfirmFamMemberId] = React.useState('');
  const [error, setError] = React.useState(false);

  const open = useMemo(() => !!node.data, [node]);

  const updateData = (dataset) => {
    // setPedigreeState(CONSTANTS.pedigreeState.modified);
    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(dataset));
    });
    setDataset(dataset);
  };

  const proceedToDelete = async () => {
    if (String(confirmFamMemberId).trim() !== String(node.data.upn)) {
      setError(true);
      return;
    }

    const member = deepCopy.find((p) => p.pid === node.data.pid);
    const addons = [];
    let parents = [member.mother, member.father];
    const existingChildrens = deepCopy.some(
      (p) =>
        parents.includes(p.mother) &&
        parents.includes(p.father) &&
        !p.noparents &&
        p.pid !== member.pid
    );

    if (member.mother && member.father && !existingChildrens) {
      const newchild = generateNewUser();
      newchild.hidden = true;
      newchild.not_set = true;
      newchild.mother = member.mother;
      newchild.father = member.father;
      addons.push(newchild);
    }

    const spouse = getSpouse(deepCopy, member.spouse);

    let children = [];
    if (spouse && spouse.deleted) {
      children = deepCopy.filter(
        (p) =>
          [member.pid, member.spouse].includes(p.mother) ||
          [member.pid, member.spouse].includes(p.father)
      );
      const node = rootConfig.find((node) => node.data.pid === children[0].pid);

      const nodesAtsameDepth = adjacent_nodes(rootConfig, node);

      let nh_node = nodesAtsameDepth[0];

      children.map((child) => {
        if (nh_node) {
          child.mother = nh_node.mother;
          child.father = nh_node.father;
          child.noparents = 'true';
        } else {
          delete child.mother;
          delete child.father;
          delete child.noparents;
          child.top_level = 'true';
        }
      });
    }

    await axios
      .post(
        APICONSTANTS.deletePedigreeFamilyMember
          .replace(':user_id', node.data.user_id)
          .replace(':member_id', node.data.member_id),
        { addons, updation_required: children }
      )
      .then((res) => {
        if (res.data.members?.length > 0) {
          deepCopy.push(...addons);
          const updated_members = res.data.members;
          updated_members.forEach((updated_member) => {
            if (updated_member.deleted) {
              const idx = deepCopy.findIndex(
                (person) => updated_member.pid === person.pid
              );
              updated_member.deleted = true;
              deepCopy[idx] = updated_member;
            } else {
              deepCopy = deepCopy.filter(
                (ped) => ped.pid !== updated_member.pid
              );
            }
          });
          updateData(deepCopy);
        } else {
          history.push(CONSTANTS.pedigreeToolPath);
        }

        setSnack({
          status: true,
          msg: 'Deleted successfully!',
          severity: 'success'
        });
      })
      .catch((err) =>
        setSnack({
          status: true,
          msg: 'An Error Occured!',
          severity: 'error'
        })
      );
    handleClose();
  };

  const onChange = (e) => {
    setConfirmFamMemberId(e.target.value);
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      showActions={true}
      saveBtnLabel={'Confirm Delete'}
      title={'Delete Confirmation'}
      titleAppearance={'warning'}
      minHeight={200}
      onSave={proceedToDelete}
      allowBackgroundClose={handleClose}
    >
      <Box>
        <Typography fontWeight={'bold'}>
          {`Warning: This action will permanently delete the following family member's details, including all associated records and data.`}
        </Typography>
        <Box py={2}>
          <Typography>UPN : {node.data.upn}</Typography>
          <Typography>
            Full Name: {node.data.firstName} {node.data.lastName}
          </Typography>
          <Typography>Family Member Id : {node.data.familyMemberId}</Typography>
          <Typography>Date of Birth: {node.data.dob}</Typography>
        </Box>
        <Typography variant="subtitle2">
          Please note that this action cannot be undone, and all historical
          information linked to this family member will be permanently removed
          from the system.
        </Typography>
        <Box className="user-form" pt={2}>
          <TextInput
            label="Enter Family Member UPN to confirm deletion"
            error={error}
            helperText={error && 'Invalid Family Member UPN'}
            placeholder={node.data.upn}
            onChange={onChange}
          />
        </Box>
      </Box>
    </CustomDialog>
  );
}

export default DeletionMenu;
