import {
  FERTILITY,
  GENDER,
  spouseRelations
} from 'src/components/questionnaire/Constants';
import { TWIN_IDENTIFIERS, TWIN_TYPES } from '../pedigreeUtil';
import * as d3 from 'd3';
export function is_fullscreen() {
  return (
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
  );
}

export const genderIconMap = (data) => {
  const gender = data.genderIdentity || data.gender;
  const map = {
    male: 'male',
    female: 'female',
    unknown: 'unknown',
    Male: 'male',
    Female: 'female',
    Indeterminate: 'unknown',
    'Male - AFAB': 'male',
    'Male - UAAB': 'male',
    'Female - AMAB': 'female',
    'Female - UAAB': 'female',
    'Non-binary/gender diverse - AFAB': 'unknown',
    'Non-binary/gender diverse - AMAB': 'unknown',
    'Non-binary/gender diverse - UAAB': 'unknown',
    'Unknown/Not Stated Unknown': 'unknown'
  };

  return map[gender];
};

export const getAcronym = (data) => {
  const gender = data.genderIdentity || data.gender;

  const acronymMap = {
    'Male - AFAB': 'AFAB',
    'Male - UAAB': 'UAAB',
    'Female - AMAB': 'AMAB',
    'Female - UAAB': 'UAAB',
    'Non-binary/gender diverse - AFAB': 'AFAB',
    'Non-binary/gender diverse - AMAB': 'AMAB',
    'Non-binary/gender diverse - UAAB': 'UAAB'
  };

  return acronymMap[gender];
};

export function get_svg_dimensions(opts) {
  return {
    width: is_fullscreen() ? window.innerWidth : opts.width,
    height: is_fullscreen() ? window.innerHeight : opts.height
  };
}

export function getNodeByName(nodes, pid) {
  if (nodes) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].data && pid === nodes[i].data.pid) return nodes[i];
      else if (pid === nodes[i].pid) return nodes[i];
    }
  }
}

export function getChildren(dataset, mother, father) {
  let children = [];
  let pids = [];
  if (mother.gender === 'female')
    dataset.forEach(function (p) {
      if (mother.pid === p.mother)
        if (!father || father.pid === p.father) {
          if (pids.indexOf(p.pid) === -1) {
            children.push(p);
            pids.push(p.pid);
          }
        }
    });
  return children;
}

export const roots = {};

// get ancestors of a node
export function ancestors(dataset, node) {
  let ancestors = [];
  function recurse(node) {
    // console.log(node);
    if (node) {
      if (node.data) node = node.data;
      if ('mother' in node && 'father' in node && !('noparents' in node)) {
        recurse(getNodeByName(dataset, node.mother));
        recurse(getNodeByName(dataset, node.father));
      }
      ancestors.push(node);
    }
    // }
  }
  recurse(node);
  return ancestors;
}

export function flatten(root) {
  let flat = [];
  function recurse(node) {
    if (node && node.children) node.children.forEach(recurse);
    flat.push(node);
  }
  recurse(root);
  return flat;
}
function contains_parent(arr, m, f) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].mother === m && arr[i].father === f) return true;
  }
  return false;
}

export function makeid(len) {
  let text = crypto.randomUUID();
  return text;
}

export function getSortedTwins(dataset = []) {
  const newDataset = [...dataset];

  newDataset.sort((a, b) => {
    // Determine the priority order: mztwin > cztwin > dztwin
    if (a.mztwin && b.mztwin) {
      if (a.mztwin === b.mztwin) {
        if (a.cztwin && b.cztwin) {
          return a.cztwin - b.cztwin; // Sort by cztwin ascending
        } else if (a.cztwin && !b.cztwin) {
          return 1; // a (with cztwin) comes before b (without cztwin)
        } else if (!a.cztwin && b.cztwin) {
          return -1; // b (with cztwin) comes before a (without cztwin)
        } else if (a.dztwin && b.dztwin) {
          return a.dztwin - b.dztwin; // Sort by dztwin ascending
        } else if (a.dztwin && !b.dztwin) {
          return -1; // a (with dztwin) comes before b (without dztwin)
        } else if (!a.dztwin && b.dztwin) {
          return 1; // b (with dztwin) comes before a (without dztwin)
        }
      }
      return a.mztwin - b.mztwin; // Sort by mztwin ascending
    } else if (a.mztwin && !b.mztwin) {
      return -1; // a (with mztwin) comes before b (without mztwin)
    } else if (!a.mztwin && b.mztwin) {
      return 1; // b (with mztwin) comes before a (without mztwin)
    } else if (a.cztwin && b.cztwin) {
      if (a.cztwin === b.cztwin) {
        if (a.dztwin && b.dztwin) {
          return a.dztwin - b.dztwin; // Sort by dztwin ascending
        } else if (a.dztwin && !b.dztwin) {
          return -1; // a (with dztwin) comes before b (without dztwin)
        } else if (!a.dztwin && b.dztwin) {
          return 1; // b (with dztwin) comes before a (without dztwin)
        }
      }
      return a.cztwin - b.cztwin; // Sort by cztwin ascending
    } else if (a.cztwin && !b.cztwin) {
      return -1; // a (with cztwin) comes before b (without cztwin)
    } else if (!a.cztwin && b.cztwin) {
      return 1; // b (with cztwin) comes before a (without cztwin)
    } else if (a.dztwin && b.dztwin) {
      return a.dztwin - b.dztwin; // Sort by dztwin ascending
    } else if (a.dztwin && !b.dztwin) {
      return -1; // a (with dztwin) comes before b (without dztwin)
    } else if (!a.dztwin && b.dztwin) {
      return 1; // b (with dztwin) comes before a (without dztwin)
    }

    return 0; // Default return value (in case all twin types are undefined or null)
  });

  return newDataset;
}

function setChildrenId(children, id) {
  children.forEach(function (p) {
    if (p) {
      if (p.id === undefined) p.id = id++;
    }
  });
  return id;
}

export function getSiblings(dataset, person, gender) {
  if (person) {
    if (person === undefined || !person.mother || person.noparents) return [];

    return dataset.filter(function (p) {
      return p.pid !== person.pid &&
        !('noparents' in p) &&
        p.mother &&
        p.mother === person.mother &&
        p.father === person.father &&
        (!gender || p.gender === gender)
        ? p
        : null;
    });
  }
}

// Returns the twins of the same person identifier
export function getTwins(dataset, person, includeCurrentPerson, identifier) {
  if (!person) {
    return [];
  }

  return dataset.filter((p) => {
    // Check if the current person (p) shares the same parents and identifier
    if (
      p.mother === person.mother &&
      p.father === person.father &&
      !('noparents' in p) &&
      p[identifier] === person[identifier]
    ) {
      return includeCurrentPerson || p.pid !== person.pid;
    }
    return false;
  });
}

const getOtherTwinIdentifiers = (currentIdentifier) => {
  const { mztwin, dztwin, cztwin } = TWIN_IDENTIFIERS;
  const allTwinIdentifiers = [mztwin, dztwin, cztwin];
  return allTwinIdentifiers.filter(
    (identifier) => identifier !== currentIdentifier
  );
};

const containsPersonWithPid = (data, person) => {
  return data.some((p) => p.pid === person.pid);
};

// Recursively returns all the twins with connected same identifiers
const getRecursiveTwins = (
  twinIdentifier,
  twinIdentifierValue,
  dataset,
  resultSet
) => {
  dataset.forEach((person) => {
    if (
      person[twinIdentifier] === twinIdentifierValue &&
      !containsPersonWithPid(resultSet, person)
    ) {
      resultSet.push(person);

      const otherTwinIdentifiers = getOtherTwinIdentifiers(twinIdentifier);

      otherTwinIdentifiers.forEach((identifier) => {
        // Retrieve the next twinIdentifierValue for the recursive call
        const nextTwinIdentifierValue = person[identifier];
        if (nextTwinIdentifierValue) {
          getRecursiveTwins(
            identifier,
            nextTwinIdentifierValue,
            dataset,
            resultSet
          );
        }
      });
    }
  });
};

const getTwinIdentifier = (person) => {
  const { mztwin, dztwin, cztwin } = TWIN_IDENTIFIERS;
  if (person.mztwin) return mztwin;
  else if (person.dztwin) return dztwin;
  else if (person.cztwin) return cztwin;
};

// Recursively returns all the connected twins with same identifier
export const getConnectedTwins = (dataset, person) => {
  const connectedTwins = [];
  const twinIdentifier = getTwinIdentifier(person);

  getRecursiveTwins(
    twinIdentifier,
    person[twinIdentifier],
    dataset,
    connectedTwins
  );
  return getSortedTwins(connectedTwins);
};

const hasAnyTwinType = (person) => {
  return person.mztwin || person.dztwin || person.cztwin;
};

// update parent node and sort twins
function updateParent(p, parent, id, nodes, dataset) {
  // add to parent node
  if ('parent_node' in p) p.parent_node.push(parent);
  else p.parent_node = [parent];

  // check twins lie next to each other
  if (hasAnyTwinType(p)) {
    let twins = getConnectedTwins(dataset, p);
    for (let i = 0; i < twins.length; i++) {
      let twin = getNodeByName(nodes, twins[i].pid);
      if (twin) twin.id = id++;
    }
  }
  return id;
}

export function getIdxByName(arr, pid) {
  let idx = arr.findIndex(function (p) {
    return pid === p.pid;
  });
  return idx;
}

function get_grandparents_idx(dataset, midx, fidx) {
  let gmidx = midx;
  let gfidx = fidx;
  while (
    dataset[gmidx]?.mother &&
    dataset[gfidx]?.mother &&
    !('noparents' in dataset[gmidx]) &&
    !('noparents' in dataset[gfidx])
  ) {
    gmidx = getIdxByName(dataset, dataset[gmidx].mother);
    gfidx = getIdxByName(dataset, dataset[gfidx].mother);
  }
  return { midx: gmidx, fidx: gfidx };
}

export function get_partners(dataset, anode) {
  let ptrs = [];
  for (let i = 0; i < dataset.length; i++) {
    let bnode = dataset[i];
    if (anode.pid === bnode.mother && ptrs.indexOf(bnode.father) === -1)
      ptrs.push(bnode.father);
    else if (anode.pid === bnode.father && ptrs.indexOf(bnode.mother) === -1)
      ptrs.push(bnode.mother);
  }
  return ptrs;
}
export function buildTree(opts, dataset, person, root, partnerLinks, id) {
  //   const dataset = _dataset.length ? _dataset : [_dataset];

  if (typeof person.children === typeof undefined)
    person.children = getChildren(dataset, person);

  partnerLinks = partnerLinks || [];
  id = id ? id : 1;
  const nodes = flatten(root);

  //console.log('NAME='+person.name+' NO. CHILDREN='+person.children.length);
  const partners = [];

  person.children.forEach(function (child) {
    dataset.forEach(function (p) {
      if (
        (child.pid === p.mother || child.pid === p.father) &&
        !('noparents' in p) &&
        child.id === undefined
      ) {
        let m = getNodeByName(nodes, p.mother);
        let f = getNodeByName(nodes, p.father);
        m = m !== undefined ? m : getNodeByName(dataset, p.mother);
        f = f !== undefined ? f : getNodeByName(dataset, p.father);
        if (!contains_parent(partners, m, f)) {
          partners.push({
            mother: m,
            father: f,
            parentRelationship: p.parentRelationship ?? spouseRelations.MARRIED,
            isParentConsanguineous: p.isParentConsanguineous ?? false
          });
        }
      }
    });
  });

  // console.log(partners);
  if (partners.length > 0) partnerLinks.push(...partners);

  // console.log(partners);

  if (partners.length > 0) {
    partners.forEach(function (ptr) {
      let mother = ptr.mother;
      let father = ptr.father;
      mother.children = [];
      let parent = {
        pid: makeid(4),
        hidden: true,
        parent: null,
        father: father,
        mother: mother,
        children: getChildren(dataset, mother, father)
      };

      let midx = getIdxByName(dataset, mother.pid);
      let fidx = getIdxByName(dataset, father.pid);

      if (!father.id && !mother.id) {
        id = setChildrenId(person.children, id);
      }

      // look at grandparents index
      let gp = get_grandparents_idx(dataset, midx, fidx);
      if (gp.fidx < gp.midx) {
        father.id = id++;
        parent.id = id++;
        mother.id = id++;
      } else {
        mother.id = id++;
        // mother.id = father.id + 1;
        parent.id = id++;
        father.id = id++;
      }
      id = updateParent(mother, parent, id, nodes, dataset);
      id = updateParent(father, parent, id, nodes, dataset);
      person.children.push(parent);
    });
    id = setChildrenId(person.children, id);
    person.children.forEach(function (p) {
      id = buildTree(opts, dataset, p, root, partnerLinks, id)[1];
    });
  }
  // console.log({ links: partnerLinks });

  return [partnerLinks, id];
}

export function getDepth(dataset, pid) {
  let idx = getIdxByName(dataset, pid);
  let depth = 1;

  while (idx >= 0 && ('mother' in dataset[idx] || dataset[idx].top_level)) {
    idx = getIdxByName(dataset, dataset[idx].mother);
    depth++;
  }
  return depth;
}

export function getAllChildren(dataset, person, gender) {
  return dataset.filter(function (p) {
    return !('noparents' in p) &&
      (p.mother === person.pid || p.father === person.pid) &&
      (!gender || p.gender === gender)
      ? p
      : null;
  });
}

export function get_tree_dimensions(opts, dataset) {
  // / get score at each depth used to adjust node separation
  let svg_dimensions = get_svg_dimensions(opts);
  svg_dimensions.height = window.innerHeight / 1.3;
  let maxscore = 0;
  let generation = {};
  for (let i = 0; i < dataset.length; i++) {
    let depth = getDepth(dataset, dataset[i].pid);
    let children = getAllChildren(dataset, dataset[i]);
    // console.log(children);
    // score based on no. of children and if parent defined
    let score =
      1 +
      (children.length > 0 ? 0.55 + children.length * 0.25 : 0) +
      (dataset[i].father ? 0.25 : 0);
    if (depth in generation) generation[depth] += score;
    else generation[depth] = score;

    if (generation[depth] > maxscore) maxscore = generation[depth];
  }

  let max_depth = Object.keys(generation).length * opts.symbol_size * 3.5;
  let tree_width =
    svg_dimensions.width - opts.symbol_size > maxscore * opts.symbol_size * 1.65
      ? svg_dimensions.width - opts.symbol_size
      : maxscore * opts.symbol_size * 1.65;
  let tree_height =
    svg_dimensions.height - opts.symbol_size > max_depth
      ? svg_dimensions.height - opts.symbol_size
      : max_depth;
  return { width: tree_width, height: tree_height };
}

export function consanguity(node1, node2, opts) {
  // parents at different depths
  if (node1.depth !== node2.depth) return true;
  let ancestors1 = ancestors(opts.dataset, node1);
  let ancestors2 = ancestors(opts.dataset, node2);
  let pids1 = ancestors1.map(function (ancestor, _i) {
    return ancestor.pid;
  });
  let pids2 = ancestors2.map(function (ancestor, _i) {
    return ancestor.pid;
  });
  let consanguity = false;
  pids1.forEach(function (pid) {
    if (pids2.indexOf(pid) !== -1) {
      consanguity = true;
      return false;
    }
  });
  return consanguity;
}

export function linkNodes(flattenNodes, partners) {
  let links = [];
  for (let i = 0; i < partners.length; i++)
    links.push({
      mother: getNodeByName(flattenNodes, partners[i].mother.pid),
      father: getNodeByName(flattenNodes, partners[i].father.pid),
      parentRelationship: partners[i]?.parentRelationship,
      isParentConsanguineous: partners[i]?.isParentConsanguineous
    });
  return links;
}
export function overlap(opts, nodes, xnew, depth, exclude_pids) {
  for (let n = 0; n < nodes.length; n++) {
    if (
      depth === nodes[n].depth &&
      exclude_pids.indexOf(nodes[n].data.pid) === -1
    ) {
      if (Math.abs(xnew - nodes[n].x) < opts.symbol_size * 1.15) return true;
    }
  }
  return false;
}

function nodesOverlap(opts, node, diff, root) {
  let descendants = node.descendants();
  let descendantsPids = descendants.map(function (descendant) {
    return descendant.data.pid;
  });
  let nodes = root.descendants();
  for (let i = 0; i < descendants.length; i++) {
    let descendant = descendants[i];
    if (node.data.pid !== descendant.data.pid) {
      let xnew = descendant.x - diff;
      if (overlap(opts, nodes, xnew, descendant.depth, descendantsPids))
        return true;
    }
  }
  return false;
}

export function adjust_coords(opts, root, flattenNodes) {
  function recurse(node) {
    if (node.children) {
      node.children.forEach(recurse);

      if (node.data.father) {
        // hidden nodes
        let father = getNodeByName(flattenNodes, node.data.father.pid);
        let mother = getNodeByName(flattenNodes, node.data.mother.pid);
        let xmid = (father.x + mother.x) / 2;
        if (
          !overlap(opts, root.descendants(), xmid, node.depth, [node.data.pid])
        ) {
          node.x = xmid; // centralise parent nodes
          let diff = node.x - xmid;
          if (
            node.children.length === 2 &&
            (node.children[0].data.hidden || node.children[1].data.hidden)
          ) {
            if (
              !(node.children[0].data.hidden && node.children[1].data.hidden)
            ) {
              let child1 = node.children[0].data.hidden
                ? node.children[1]
                : node.children[0];
              let child2 = node.children[0].data.hidden
                ? node.children[0]
                : node.children[1];
              if (
                ((child1.x < child2.x && xmid < child2.x) ||
                  (child1.x > child2.x && xmid > child2.x)) &&
                !overlap(opts, root.descendants(), xmid, child1.depth, [
                  child1.data.pid
                ])
              ) {
                child1.x = xmid;
              }
            }
          } else if (
            node.children.length === 1 &&
            !node.children[0].data.hidden
          ) {
            if (
              !overlap(opts, root.descendants(), xmid, node.children[0].depth, [
                node.children[0].data.pid
              ])
            )
              node.children[0].x = xmid;
          } else {
            if (diff !== 0 && !nodesOverlap(opts, node, diff, root)) {
              if (node.children.length === 1) {
                node.children[0].x = xmid;
              } else {
                let descendants = node.descendants();
                if (opts.DEBUG)
                  console.log(
                    'ADJUSTING ' +
                      node.data.pid +
                      ' NO. DESCENDANTS ' +
                      descendants.length +
                      ' diff=' +
                      diff
                  );
                for (let i = 0; i < descendants.length; i++) {
                  if (node.data.pid !== descendants[i].data.pid)
                    descendants[i].x -= diff;
                }
              }
            }
          }
        } else if (
          (node.x < father.x && node.x < mother.x) ||
          (node.x > father.x && node.x > mother.x)
        ) {
          node.x = xmid; // centralise parent nodes if it doesn't lie between mother and father
        }
      }
    }
  }
  recurse(root);
  recurse(root);
}

// return the array of all possilble child of the two spouses
export const getConnectedChildren = (
  totalSpouses = [],
  selectedSpouseValue
) => {
  if (totalSpouses.length === 0) return [];
  const selectedSpouse = selectedSpouseValue ?? totalSpouses[0].value;

  const connectedSpouse = totalSpouses.find(
    (spouse) => spouse.value === selectedSpouse
  );
  if (connectedSpouse) return connectedSpouse.childData || [];
  return [];
};

// Returns all connected spouses and their children related to a node
// UnsavedNode=false returns only saved nodes
// UnsavedNode=true returns saved as well as unsaved nodes
export const getSpousesWithChildren = (data, dataset, unsavedNode = false) => {
  // if node is unsaved
  if (!unsavedNode && !data?.upn && !data?.proBandId) return;

  const spousesWithChildren = [];
  const targetPersonGender =
    data.gender === GENDER.female ? 'father' : 'mother';
  dataset.forEach((person) => {
    // finding children of the target node
    if (
      (person.mother === data.pid || person.father === data.pid) &&
      !('noparents' in person)
    ) {
      //finding connnected spouse of opposite gender
      const spouseNode = getNodeByName(dataset, person[targetPersonGender]);
      if (!unsavedNode && !spouseNode?.upn && !spouseNode?.proBandId) return;

      // finding the index of existing spouse if present
      const existingSpouseIndex = spousesWithChildren.findIndex(
        (person) => person.spouse.pid === spouseNode.pid
      );

      // if spouse already present then pushing node to his child
      if (existingSpouseIndex !== -1) {
        spousesWithChildren[existingSpouseIndex].childData.push(person);
      } else {
        const label = spouseNode?.upn ?? (spouseNode?.proBandId ? '1' : null);
        // Adding new spouse and its children to the array
        spousesWithChildren.push({
          childData: [person],
          spouse: spouseNode,
          value: spouseNode.pid,
          label: label
        });
      }
    }
  });
  return spousesWithChildren;
};

export const getDivorcedPath = (x1, x2, dy1) => {
  return (
    'M' +
    (x1 + (x2 - x1) * 0.5 + 1) +
    ',' +
    (dy1 - 10) +
    'L' +
    (x1 + (x2 - x1) * 0.5 - 11) +
    ',' +
    (dy1 + 10) +
    'M' +
    (x1 + (x2 - x1) * 0.5 + 11) +
    ',' +
    (dy1 - 10) +
    'L' +
    (x1 + (x2 - x1) * 0.5 - 1) +
    ',' +
    (dy1 + 10)
  );
};

const getSeparatedPath = (x1, x2, dy1) => {
  return (
    'M' +
    (x1 + (x2 - x1) * 0.5 + 6) +
    ',' +
    (dy1 - 10) +
    'L' +
    (x1 + (x2 - x1) * 0.5 - 6) +
    ',' +
    (dy1 + 10)
  );
};

const getConsanguineousPath = (x1, x2, dy1, dy2, cshift) => {
  return (
    'M' +
    x1 +
    ',' +
    dy1 +
    'L' +
    x2 +
    ',' +
    dy2 +
    'M' +
    x1 +
    ',' +
    (dy1 + cshift) +
    'L' +
    x2 +
    ',' +
    (dy2 + cshift)
  );
};

export const getPathCoordinates = (node) => {
  const x1 = node.mother.x < node.father.x ? node.mother.x : node.father.x;
  const x2 = node.mother.x < node.father.x ? node.father.x : node.mother.x;
  const dy1 = node.mother.y;
  const cdy1 = node.mother.x < node.father.x ? node.mother.y : node.father.y;
  const cdy2 = node.mother.x < node.father.x ? node.father.y : node.mother.y;

  return {
    x1,
    x2,
    dy1,
    cdy1,
    cdy2
  };
};

export const getHiddenPath = (node) => {
  const { x1, x2, dy1 } = getPathCoordinates(node);
  const isHideMother = node.mother.data.isHide;
  const isHideFather = node.father.data.isHide;
  const isLeftFather = node.father.x < node.mother.x;

  if (isHideMother && isHideFather) {
    // Hide the whole path between partners
    return;
  } else if (isHideFather) {
    let newX1 = isLeftFather ? (x1 + x2) / 2 : x1;
    let newX2 = isLeftFather ? x2 : (x1 + x2) / 2;

    // Clip the half path between partners
    return 'M' + newX1 + ',' + dy1 + 'L' + newX2 + ',' + dy1;
  } else if (isHideMother) {
    let newX1 = isLeftFather ? x1 : (x1 + x2) / 2;
    let newX2 = isLeftFather ? (x1 + x2) / 2 : x2;

    // Clip the half path between partners
    return 'M' + newX1 + ',' + dy1 + 'L' + newX2 + ',' + dy1;
  }
};

export const getRenderPath = (node) => {
  const { x1, x2, dy1, cdy1, cdy2 } = getPathCoordinates(node);
  const cshift = 4;

  const divorcedPath = getDivorcedPath(x1, x2, dy1);
  const separatedPath = getSeparatedPath(x1, x2, dy1);

  const path = 'M' + x1 + ',' + dy1 + 'L' + x2 + ',' + dy1;
  const consanguineousPath = getConsanguineousPath(x1, x2, cdy1, cdy2, cshift);

  // finding hidden parent
  const isHideMother = node.mother.data.isHide;
  const isHideFather = node.father.data.isHide;

  // returning hidden path
  if (isHideFather || isHideMother) return getHiddenPath(node);

  const { parentRelationship, isParentConsanguineous } = node;
  const renderPath = isParentConsanguineous ? consanguineousPath : path;

  switch (parentRelationship) {
    case spouseRelations.DIVORCED:
      return renderPath + divorcedPath;
    case spouseRelations.SEPARATED:
      return renderPath + separatedPath;
    case spouseRelations.CASUAL:
      return path;
    default:
      return renderPath;
  }

  // Special case for casusal consanguineous path is handled in render function
};

// Func for drawing fertility lines for partner relationships
export const checkFertile = (d, dataset = []) => {
  let pathData = '';
  const hasChild = dataset.some((mem) => {
    return (
      mem.father === d.father.data.pid &&
      mem.mother === d.mother.data.pid &&
      mem.hidden !== 'true' &&
      mem.not_set !== 'true'
    );
  });

  if (hasChild) return pathData;

  let x1 = d.father.x;
  let x2 = d.mother.x;
  let dy1 = d.father.y;
  const midX = x1 + (x2 - x1) * 0.5;
  const len = 16;

  let fertility = null;

  if (d.father.data.relation_fertility?.length > 0) {
    let rel = d.father.data.relation_fertility.find(
      (rl) => rl.spouse === d.mother.data.pid
    );
    if (rel) fertility = rel.fertility;
  }

  if (d.mother.data.relation_fertility?.length > 0) {
    let rel = d.mother.data.relation_fertility.find(
      (rl) => rl.spouse === d.father.data.pid
    );
    if (rel) fertility = rel.fertility;
  }

  if (fertility === FERTILITY.infertile) {
    pathData = ` M ${midX - len} ${dy1 + 95} H ${
      midX + len
    } M ${midX} ${dy1} V ${dy1 + 95} M ${midX - len} ${dy1 + 100} H ${
      midX + len
    }`;
  } else if (fertility === FERTILITY.fertile) {
    pathData = ` M ${midX - len} ${dy1 + 95} H ${
      midX + len
    } M ${midX} ${dy1} V ${dy1 + 95} `;
  }

  return pathData;
};

export const get_bracket = (dx, dy, indent, opts) => {
  return (
    'M' +
    (dx + indent) +
    ',' +
    dy +
    'L' +
    dx +
    ' ' +
    dy +
    'L' +
    dx +
    ' ' +
    (dy + opts.symbol_size * 1.28) +
    'L' +
    dx +
    ' ' +
    (dy + opts.symbol_size * 1.28) +
    'L' +
    (dx + indent) +
    ',' +
    (dy + opts.symbol_size * 1.28)
  );
};

// returns unique twin Id for distinguish twins
export function getUniqueTwinID(dataset, twin_type) {
  let uniqueIdentifiers = Array.from(
    { length: dataset.length },
    (_, i) => i + 1
  );
  for (let i = 0; i < dataset.length; i++) {
    if (dataset[i][twin_type]) {
      let idx = uniqueIdentifiers.indexOf(dataset[i][twin_type]);
      if (idx > -1) uniqueIdentifiers.splice(idx, 1);
    }
  }
  if (uniqueIdentifiers.length > 0) return uniqueIdentifiers[0];
  return undefined;
}

export const getTwinTypeIdentifier = (twinType) => {
  const { mztwin, dztwin, cztwin } = TWIN_IDENTIFIERS;
  const { identical, nonIdentical, unknown } = TWIN_TYPES;
  switch (twinType) {
    case identical:
      return mztwin;

    case nonIdentical:
      return dztwin;

    case unknown:
      return cztwin;

    default:
      return undefined;
  }
};

// setting monozygotic (mztwin) or dizygotic (dztwin) or unknowzygosity (cztwin) twin identifiers
export function setTwinIdentifier(dataset, person, twinMember, twinType) {
  const twinIdentifier = getTwinTypeIdentifier(twinType);

  // If twinMember has twinIdentifier and person does not, synchronize person with twinMember
  if (twinMember[twinIdentifier] && !person[twinIdentifier]) {
    person[twinIdentifier] = twinMember[twinIdentifier];
    return;
  }

  // If neither person nor twinMember has twinIdentifier, generate a unique ID and assign to both
  if (!person[twinIdentifier] && !twinMember[twinIdentifier]) {
    const uniqueTwinID = getUniqueTwinID(dataset, twinIdentifier);

    person[twinIdentifier] = uniqueTwinID;
    twinMember[twinIdentifier] = uniqueTwinID;
    return;
  }
}

export const removeTwinIdentifiers = (dataset) => {
  return dataset.map((p) => {
    const person = { ...p };
    delete person.dztwin;
    delete person.mztwin;
    delete person.cztwin;
    return person;
  });
};

// sorts the children by the single birth
export const sortSingleBirthChildren = (dataset) => {
  const sortedChildren = [];
  dataset.forEach((person) => {
    // Check if the person already exists in the sorted array
    const personExist = sortedChildren.some((elem) => elem.pid === person.pid);

    if (personExist) return;

    // Check if the person has no twin types
    if (!hasAnyTwinType(person)) {
      sortedChildren.push(person);
    } else {
      // Get all connected twins the current person
      const connectedChildren = getConnectedTwins(dataset, person);

      const isTwinExist = connectedChildren.some((p) => hasAnyTwinType(p));

      if (isTwinExist) {
        // Sort and push connected twins into the sorted array
        sortedChildren.push(...getSortedTwins(connectedChildren));
      } else {
        sortedChildren.push(...connectedChildren);
      }
    }
  });
  return sortedChildren;
};

// assigning twin identifiers and sort the twins
export const getOrganizeTwinData = (dataset) => {
  const newDataset = removeTwinIdentifiers(dataset);

  const children = [];
  const others = [];

  newDataset.forEach((p) => {
    if (p.isTwin && p.twinMember) {
      const twinMember = getNodeByName(newDataset, p.twinMember);
      if (twinMember) {
        setTwinIdentifier(newDataset, p, twinMember, p.twinType);
      }
    }

    if (p.mother && p.father && !('noparents' in p)) {
      children.push(p);
    } else {
      others.push(p);
    }
  });

  // Sort childrenIncluded array by twin status to keep twins together
  const sortedChildren = sortSingleBirthChildren(children);

  // Combine childrenExcluded with sorted childrenIncluded
  return [...others, ...sortedChildren];
};

export const getQuestionPath = (x, y) => {
  return `M ${x} ${y} C ${x + 8} ${y - 3} ${x + 12} ${y + 12} ${x} ${
    y + 10
  } L ${x} ${y + 19}`;
};

export const getLabelPos = (d, options) => {
  const pos_y = 1.3 * options.symbol_size;
  const spacing = options.fontSize * 1.35;

  let currentY = pos_y;

  const acronym_y = getAcronym(d.data) ? currentY : null;
  currentY += acronym_y !== null ? spacing : 0;

  const name_y =
    (options.labelsSet.firstName && d.data.firstName) ||
    (options.labelsSet.lastName && d.data.lastName)
      ? currentY
      : null;
  currentY += name_y !== null ? spacing : 0;

  const upn_y =
    options.labelsSet.upn && (d.data.proBandId || d.data.upn) ? currentY : null;
  currentY += upn_y !== null ? spacing : 0;

  const age_y = options.labelsSet.age && d.data.dob ? currentY : null;
  currentY += age_y !== null ? spacing : 0;

  const phenotypes_y =
    d.data.phenotypes &&
    d.data.phenotypes.length > 0 &&
    (options.labelsSet.phenotypesName || options.labelsSet.phenotypesID)
      ? currentY
      : null;
  currentY += phenotypes_y !== null ? spacing : 0;

  const com_y = currentY;

  const pos = { acronym_y, upn_y, name_y, age_y, phenotypes_y, com_y };

  return pos;
};

export function wrap(text, width) {
  text.each(function () {
    const textElement = d3.select(this);
    const originalText = textElement.text();

    const lines = originalText.split(/\r?\n/);
    const lineHeight = 14 * 1.3;

    textElement.text('');

    let global_lineNumber = 0;
    lines.forEach((lineText, i) => {
      const words = lineText.split(/\s+/).reverse();

      let word;
      let line = [];
      let lineNumber = global_lineNumber + i;
      let y = textElement.attr('y');

      let tspan = textElement
        .append('tspan')
        .attr('x', -85)
        .attr('y', y)
        .attr('dy', lineNumber * lineHeight + 'px');

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(' '));

        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));

          line = [word];
          ++global_lineNumber;
          tspan = textElement
            .append('tspan')
            .attr('x', -85)
            .attr('y', y)
            .attr('dy', ++lineNumber * lineHeight + 'px')
            .text(word);
        }
      }
    });
  });
}
