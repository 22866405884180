import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';
import AssociatedNote from '../../../UI/input/AssociatedNote';
import axios from 'src/axios';

import UploadFileList from 'src/components/UI/input/UploadFileList';

import { Box, Button, MenuItem, Select, TextField } from '@mui/material';

import { FormControl } from '@mui/material';
import { formatDate } from 'src/util/util';
import { SnackContext, AppContext, ThemeContext } from 'src/store/ContextStore';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { getReferralTags, handleUpload, getDependencies } from './formService';
import DateInput from '../../../UI/input/DateInput';
import ClinicalNoteDeleteModal from '../ClinicalNoteDeleteModal';

export default function ClinicalNoteForm({
  handleClose,
  familyMemberId,
  isEditing,
  model
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const { theme } = useContext(ThemeContext);

  const defaultValues = isEditing
    ? { ...model, fileAttachments: [] }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        date: new Date(),
        clinician: '',
        note: '',
        private: false,
        showInFamily: false,
        caution: false,
        pinned: false,
        associatedReferral: '',
        fileAttachments: []
      };

  const [formValues, setFormValues] = React.useState(defaultValues);

  let [referralOptions, setReferralOptions] = React.useState(['loading...']);
  const { setSnack } = React.useContext(SnackContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const deleteNote = async (e) => {
    setLoading(true);

    const dep = await getDependencies(
      user.familyId,
      model._id,
      theme.dateFormat
    );
    if (dep.length !== 0) {
      setSnack({
        status: true,
        msg: `Associated record exists; check the ${dep[0]}`,
        severity: 'error'
      });
      setLoading(false);
      return;
    }
    axios
      .delete(`/clinical-notes/note`, {
        params: {
          _id: model._id
        }
      })
      .then((res) => {
        setSnack({
          status: true,
          msg: 'Record deleted',
          severity: 'success'
        });
        handleCloseModal();
        handleClose();
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Delete failed! ${err}`,
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (e) => {
    let fileAttachments = formValues.fileAttachments;
    const deleted = formValues.fileAttachments[e];

    axios.delete(`${APICONSTANTS.fileAttachment}`, {
      params: {
        fileName: deleted
      }
    });
    fileAttachments = fileAttachments.filter((attachment, i) => i !== e);
    setFormValues((prevValues) => ({
      ...prevValues,
      fileAttachments
    }));
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const referralTags = await getReferralTags(
        theme,
        familyMemberId,
        formValues.familyId
      );
      setReferralOptions(referralTags);
    };
    if (referralOptions != [] && referralOptions[0] == 'loading...')
      fetchData();
  }, []);

  const handleChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const handleFile = (e) => {
    handleUpload(e);
    let fileAttachments = formValues.fileAttachments;
    fileAttachments.push(e.target.files[0].name);
    setFormValues({
      ...formValues,
      fileAttachments: fileAttachments
    });
  };

  const onToggleSwitch = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.checked
    }));
    if (e.target.name == 'showInFamily' && formValues.private) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [e.target.name]: false
      }));
    }
    if (e.target.name == 'private') {
      setFormValues((prevValues) => ({
        ...prevValues,
        showInFamily: false
      }));
    }
    if (familyMemberId == 'none') {
      setFormValues((prevValues) => ({
        ...prevValues,
        showInFamily: true
      }));
    }
  };

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return axios
      .put(APICONSTANTS.clinicalNote, formValues)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <ClinicalNoteDeleteModal
        loading={loading}
        open={open}
        handleCloseModal={handleCloseModal}
        onConfirm={deleteNote}
        title="Are you sure you want to delete this Clinical Note ?"
        content="Warning: This action will permanently delete this Clinical Note. Once deleted, this
        information will be permanently removed and cannot be restored."
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Clinician</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="clinician"
              value={formValues.clinician}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Date</FormLabel>
          </Grid>
          <DateInput
            value={
              formValues.date ? new Date(formatDate(formValues.date)) : null
            }
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                date: e
              }))
            }
            variant="outline"
            minDate={isEditing ? new Date(1600, 0, 0) : new Date()}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Associated Referral</FormLabel>
          </Grid>
          <AssociatedNote
            name="associatedReferral"
            handleChange={handleChange}
            value={formValues.associatedReferral}
            noteOptions={referralOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Note</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="note"
              value={formValues.note}
              multiline={true}
            />
          </Box>
        </Grid>
        <UploadFileList
          value={formValues.fileAttachments}
          onChange={(value) =>
            setFormValues({
              ...formValues,
              fileAttachments: value
            })
          }
        />
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Private
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.private ?? false}
                onChange={onToggleSwitch}
                name="private"
              />
            </Grid>
            <Grid item>
              <Typography>{formValues.private ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Show in family
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.showInFamily ?? false}
                onChange={onToggleSwitch}
                name="showInFamily"
              />
            </Grid>
            <Grid item>
              <Typography>{formValues.showInFamily ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Caution
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.caution ?? false}
                onChange={onToggleSwitch}
                name="caution"
              />
            </Grid>
            <Grid item>
              <Typography>{formValues.caution ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Pinned
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.pinned ?? false}
                onChange={onToggleSwitch}
                name="pinned"
              />
            </Grid>
            <Grid item>
              <Typography>{formValues.pinned ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          handleClose={handleClose}
          intermediateBtnLabel="Delete"
          intermediateAction={isEditing ? handleOpenModal : null}
          addLabel={`Add new clinical note`}
        />
      </Grid>
    </form>
  );
}
