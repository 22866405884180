export const getSpouse = (dataset, spid) => {
  return dataset.find((p) => p.pid === spid);
};

export const getNodesAtDepth = (fnodes, depth) => {
  return fnodes
    .filter((node) => node?.depth === depth && !node?.data.hidden)
    .sort((a, b) => a.x - b.x);
};

export const adjacent_nodes = (root, node) => {
  let excludes = [node?.data.mother, node?.data.father];
  let dnodes = getNodesAtDepth(root, node?.depth);

  let nodesAtsameDepth = dnodes
    .filter(
      (node) =>
        !(
          excludes.includes(node?.data.father) ||
          excludes.includes(node?.data.mother)
        )
    )
    .map((node) => node.data);

  return nodesAtsameDepth;
};
