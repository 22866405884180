import React, { useContext, useState, useMemo, useEffect } from 'react';

import { Button, Chip, Card, CardContent, CardActionArea } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CircularProgress from '@mui/material/CircularProgress';
import TextInput from '../../UI/input/TextInput';
import axios from 'src/axios';
import { APICONSTANTS } from '../../Constants';
import { AppContext, SnackContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { basePath as customFormBasePath } from 'src/components/custom-form/Constants';
import { GLOBAL_CONSTANTS } from 'src/components/Constants';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import ViewFormResponseModal from './ViewFormResponseModal';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  chip: {
    backgroundColor: '#4d9fb3',
    color: 'white',
    marginLeft: '8px',
    flexShrink: 0
  }
});

const FormSubmission = ({ data }) => {
  const classes = useStyles();
  const { content } = useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const [template, setTemplate] = useState(null);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState([]);

  const t = useAutoTranslation();
  const { setSnack } = useContext(SnackContext);

  let email = useMemo(() => {
    return data?.data?.email;
  }, [data]);

  // Id for family member
  let familyMemberId = useMemo(() => {
    return data?.data?.familyMemberId;
  }, [data]);

  // Id for proband
  let proBandId = useMemo(() => {
    return data?.data?.proBandId;
  }, [data]);

  // Here fetch all form responses associated with this family member
  const getFormResponses = () => {
    const id = proBandId ?? familyMemberId;
    if (!id) return;

    axios
      .get(
        APICONSTANTS.formResponsesByFamilyMember.replace(':familyMemberId', id)
      )
      .then((res) => {
        setResponses(res.data || []);
      })
      .catch(() => {
        //
      });
  };

  const fetchTemplateOptions = () => {
    axios
      .get(APICONSTANTS.formBuilderSchemas)
      .then((res) => {
        const schemas = res.data || [];
        const formSchemas = schemas.map((el) => ({
          _id: el._id,
          label: el.title,
          type: 'form'
        }));
        setTemplateOptions(formSchemas);
      })
      .catch(() => {
        //
      });
  };

  useEffect(() => {
    fetchTemplateOptions();
  }, []);

  useEffect(() => {
    getFormResponses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyMemberId, proBandId]);

  const onSubmit = (e, withEmail = true) => {
    e.preventDefault();

    const data = [email];
    const id = proBandId ?? familyMemberId;
    const familyId = user.familyId;

    if (!template?._id || !email || !id || !familyId) {
      return setSnack({
        status: true,
        msg: 'Kindly save pedigree!',
        severity: 'error'
      });
    }

    setLoading(true);

    axios
      .post(APICONSTANTS.formBuilderGenerateLink, {
        emails: data,
        template: template,
        familyId: user.familyId,
        familyMemberId: id,
        withEmail,
        // pass the base path and token key that is used to generate a link
        ...(withEmail && {
          formBasePath: customFormBasePath,
          tokenKey: GLOBAL_CONSTANTS.tokenKey
        })
      })
      .then((res) => {
        getFormResponses();

        setLoading(false);
        setTemplate(null);

        setSnack({
          status: true,
          msg: 'Link(s) generated successfully',
          severity: 'success'
        });
      })
      .catch(() => {
        setLoading(false);
        setSnack({
          status: true,
          msg: 'An error has occurred!',
          severity: 'error'
        });
      });
  };

  const onResponseClick = (response) => {
    setSelectedResponse(response);
    setOpenModal(true);
  };

  const handleModalClose = () => setOpenModal(false);

  return (
    <div style={{ maxWidth: 390 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            label="Select form template"
            type="simple-autocomplete"
            name="template"
            value={template || null}
            onChange={(e, newValue) => setTemplate(newValue)}
            options={templateOptions}
            id="form-template"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                endIcon={
                  loading ? (
                    <CircularProgress size={15} />
                  ) : (
                    <EmailIcon size="small" />
                  )
                }
                variant="contained"
                color="secondary"
                onClick={onSubmit}
                disabled={!template || !email || loading}
              >
                {t(`Generate Link and send E-mail`)}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {responses.map((response) => (
            <Card key={response._id} style={{ marginBottom: '16px' }}>
              <CardActionArea onClick={() => onResponseClick(response)}>
                <CardContent>
                  <div className={classes.container}>
                    <Typography
                      variant="h6"
                      component="div"
                      className={classes.title}
                    >
                      #{response.responseId} - {response.form.title}
                    </Typography>
                    <Chip
                      label={response.status}
                      variant="filled"
                      className={classes.chip}
                    />
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Grid>

        {openModal && (
          <CustomDialog
            title={'View Form Submission'}
            open={openModal}
            handleClose={handleModalClose}
            minHeight="auto"
          >
            <ViewFormResponseModal
              readOnly
              selectedResponse={selectedResponse}
            />
          </CustomDialog>
        )}
      </Grid>
    </div>
  );
};

export default FormSubmission;
