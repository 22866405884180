import React from 'react';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomIconBtn from '../../UI/button/CustomIconBtn';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { jsPDF } from 'jspdf';
import { downloadURI, downloadXML, downloadFile } from 'src/util/util';
import axios from 'src/axios';
import { APICONSTANTS } from 'src/components/Constants';
import { SnackContext } from 'src/store/ContextStore';
import { Canvg } from 'canvg';
import html2canvas from 'html2canvas';
import { ThemeContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';

export default function ExportMenu(props) {
  const { user } = props;
  const { _id, familyId } = user;
  const { setSnack } = React.useContext(SnackContext);
  const { theme } = React.useContext(ThemeContext);

  const exportImage = async (export_pdf = false) => {
    const svgElement = document.getElementById('pedigree-chart');
    const diagnosesLegend = document.getElementById('diagnoses-legend');
    const pedigreeIdentifier = document.getElementById('pedigree-identifier');
    const pedigreeDates = document.getElementById('pedigree-dates');

    const padding = 20;
    const backgroundColor =
      theme.appearance.value === 'light' ? 'white' : theme.appearance.shade; // Set canvas background color

    const captureElement = async (element, options = {}) => {
      if (!element) return null;
      const canvas = await html2canvas(element, {
        backgroundColor,
        scale: 2,
        useCORS: true,
        ...options
      });
      const image = new Image();
      image.src = canvas.toDataURL();
      return image;
    };

    const diagnosesLegendImage = await captureElement(diagnosesLegend);
    const pedigreeIdentifierImage = await captureElement(pedigreeIdentifier);
    const pedigreeDatesImage = await captureElement(pedigreeDates);

    // Serialize SVG to render it on the canvas
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgElement);

    // Dynamically calculate canvas dimensions
    const canvasWidth =
      Math.max(svgElement.clientWidth, diagnosesLegendImage?.width || 0) +
      padding * 2;

    const canvasHeight =
      (pedigreeIdentifierImage?.height || 0) + // Height of pedigree identifier
      (diagnosesLegendImage?.height || 0) + // Height of diagnoses legend
      svgElement.clientHeight + // Height of the SVG
      (pedigreeDatesImage?.height || 0) + // Height of pedigree dates
      padding * 5; // Extra padding for spacing

    const canvas = document.createElement('canvas');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const ctx = canvas.getContext('2d');

    // Fill canvas background
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    // Draw pedigree identifier
    if (pedigreeIdentifierImage) {
      ctx.drawImage(pedigreeIdentifierImage, padding, padding);
    }

    // Draw diagnoses legend
    let legendY = padding + (pedigreeIdentifierImage?.height || 0);
    if (diagnosesLegendImage) {
      ctx.drawImage(diagnosesLegendImage, padding, legendY);
    }

    // Render the SVG
    const svgImage = new Image();
    svgImage.src = `data:image/svg+xml;base64,${btoa(svgString)}`;
    await new Promise((resolve) => {
      svgImage.onload = () => {
        const svgX = padding;
        const svgY = legendY + (diagnosesLegendImage?.height || 0) + padding;
        ctx.drawImage(svgImage, svgX, svgY); // Draw SVG
        resolve();
      };
    });

    // Draw pedigree dates at the bottom-right corner
    if (pedigreeDatesImage) {
      const x = canvas.width - pedigreeDatesImage.width - padding;
      const y = canvas.height - pedigreeDatesImage.height - padding;
      ctx.drawImage(pedigreeDatesImage, x, y);
    }

    const finalImage = canvas.toDataURL('image/png');
    if (export_pdf) {
      // Export to PDF
      const pdf = new jsPDF({
        orientation: canvasWidth > canvasHeight ? 'landscape' : 'portrait',
        unit: 'px',
        format: [canvasWidth, canvasHeight]
      });

      pdf.addImage(finalImage, 'PNG', 0, 0, canvasWidth, canvasHeight);
      pdf.save(`${familyId}.pdf`);
    } else {
      // Export as PNG
      const link = document.createElement('a');
      link.href = finalImage;
      link.download = `${familyId}.png`;
      link.click();
    }
  };

  const exportPDF = () => {
    //export image as pdf
    exportImage(true);
  };
  const getXMLData = () => {
    axios
      .get(APICONSTANTS.getXMLData.replace(':user_id', _id))
      .then((res) => {
        downloadXML(res.data, familyId);
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'Error exporting as XML data',
          severity: 'error'
        });
      });
  };

  const getJsonData = () => {
    axios
      .get(APICONSTANTS.getGA4JsonData.replace(':user_id', _id))
      .then((res) => {
        downloadFile(JSON.stringify(res.data), familyId, 'json');
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'Error converting to JSON',
          severity: 'error'
        });
      });
  };

  const t = useAutoTranslation();

  return (
    <Grid container alignItems="center">
      <Grid item>
        <FormGroup column>
          <FormControlLabel
            control={
              <CustomIconBtn
                icon={<AddPhotoAlternateIcon />}
                tooltip="Image"
                onClick={() => exportImage()}
              />
            }
            label={t('PNG')}
          />
          <FormControlLabel
            control={
              <CustomIconBtn
                icon={<PostAddIcon />}
                tooltip="PDF"
                onClick={exportPDF}
              />
            }
            label={t('PDF')}
          />
          <FormControlLabel
            control={
              <CustomIconBtn
                icon={<LibraryBooksOutlinedIcon />}
                tooltip="XML"
                onClick={getXMLData}
              />
            }
            label="XML"
          />
          <FormControlLabel
            control={
              <CustomIconBtn
                icon={<DataObjectIcon />}
                tooltip="JSON"
                onClick={getJsonData}
              />
            }
            label="JSON"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}
