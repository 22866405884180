import React, { useContext } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Button,
  Checkbox,
  Box
} from '@mui/material';
import DateInput from 'src/components/UI/input/DateInput';
import { validatePhn } from 'src/util/util';
import moment from 'moment';

import Switch from '@mui/material/Switch';
import FormLabel from 'src/components/UI/input/CustomFormLabel';
import {
  CONSTANTS,
  allEthnicities,
  ethnicityMap
} from 'src/components/questionnaire/Constants';
import SelectInput from 'src/components/UI/input/SelectInput';
import RadioInput from 'src/components/UI/input/RadioInput';
import TextInput from 'src/components/UI/input/TextInput';
import { ThemeContext, SnackContext } from 'src/store/ContextStore';
import { validateEmail } from 'src/util/util';
import MultipleSelect from 'src/components/UI/input/MultipleSelectInput';

function PersonMenu({
  formValues,
  handleChange,
  handleSubmit,
  dateChangeHandler,
  handleClose
}) {
  const { theme } = useContext(ThemeContext);

  const [DOBErrorText, setDOBErrorText] = React.useState('');
  const [DOBError, setDOBError] = React.useState(false);
  const isDOBValid = () => {
    return (
      !formValues.dob ||
      (moment(new Date()) > moment(formValues.dob) &&
        (!formValues.dod || !(moment(formValues.dod) < moment(formValues.dob))))
    );
  };

  const [DODErrorText, setDODErrorText] = React.useState('');
  const [DODError, setDODError] = React.useState(false);
  const isDODValid = () => {
    return (
      !formValues.dod ||
      (moment(new Date()) > moment(formValues.dod) &&
        (!formValues.dob || !(moment(formValues.dod) < moment(formValues.dob))))
    );
  };

  React.useEffect(() => {
    let err = !isDOBValid();
    setDOBError(err);

    err = !isDODValid();
    setDODError(err);
  });

  const validDates = () => {
    return isDOBValid() && isDODValid();
  };

  const { setSnack } = React.useContext(SnackContext);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <div style={{ maxWidth: 600 }} className="user-form">
        <Grid container spacing={2}>
          {/* gender */}
          <Grid item xs={12} id="top">
            <FormControl component="fieldset">
              <Grid container spacing={1}>
                <Grid item>
                  <FormLabel>Gender </FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <SelectInput
                    fullWidth={true}
                    variant="outlined"
                    label={formValues.genderIdentity}
                    name="genderIdentity"
                    options={CONSTANTS.genderIdentityOptions}
                    value={
                      formValues.genderIdentity ??
                      formValues?.gender[0].toUpperCase() +
                        formValues.gender.substring(1)
                    }
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <div className="margin-top-3"></div>
            <RadioInput
              label="Sex Assigned at Birth"
              name="gender"
              value={formValues.gender || ''}
              options={CONSTANTS.genderOptions}
              row
              disabled
            />
          </Grid>

          {/* isAlive */}
          <Grid item xs={12}>
            <div className="margin-top-3"></div>
            <FormControl component="fieldset">
              <FormLabel component="label" className="form-label">
                Alive or Deceased?
              </FormLabel>
              <RadioGroup
                aria-label="isAlive"
                name="isAlive"
                value={formValues.isAlive ?? ''}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio size="small" />}
                  label="Alive"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio size="small" />}
                  label="Deceased"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* name */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  First Name
                </FormLabel>
                <TextInput
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Last Name
                </FormLabel>
                <TextInput
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* date */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Date of Birth
                </FormLabel>

                <DateInput
                  value={formValues.dob}
                  onChange={(date) => {
                    setDOBError(false);
                    setDODError(false);
                    dateChangeHandler('dob', date);
                  }}
                  disableFuture
                  error={DOBError}
                  helperText={DOBErrorText}
                  liveText={true}
                  helperTextColour="red"
                  slotProps={{ textField: { fullWidth: true } }}
                />

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="estimatedDob"
                        checked={formValues['estimatedDob']}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Exact Date Unknown"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Date of Death
                </FormLabel>
                <DateInput
                  format={theme.dateFormat}
                  disabled={formValues.isAlive === 'true'}
                  value={formValues.dod}
                  onChange={(date) => {
                    setDOBError(false);
                    setDODError(false);
                    dateChangeHandler('dod', date);
                  }}
                  disableFuture
                  error={DODError}
                  helperText={DODErrorText}
                  liveText={true}
                  helperTextColour="red"
                  slotProps={{ textField: { fullWidth: true } }}
                />
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="estimatedDod"
                        checked={formValues['estimatedDod']}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Exact Date Unknown"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* ethnicity */}
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    label={'Ethnicity'}
                    name="ethnicity"
                    type="multiple-select"
                    value={formValues?.ethnicity || []}
                    onChange={handleChange}
                    options={allEthnicities}
                    mapper={ethnicityMap}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          {!!formValues.spouse && !!formValues.totalSpouse?.length && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {/* Spouse Relationship */}
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="flex-end">
                    {formValues.totalSpouse.length > 1 && (
                      <Grid item xs={5}>
                        <FormControl component="fieldset" fullWidth>
                          <Grid container spacing={1}>
                            <Grid item>
                              <FormLabel>Select Spouse</FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                              <SelectInput
                                fullWidth={true}
                                variant="outlined"
                                label={'Select Spouse'}
                                name="selectedSpouse"
                                options={formValues.totalSpouse}
                                value={formValues.selectedSpouse}
                                onChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormControl component="fieldset" fullWidth>
                        <Grid container spacing={1}>
                          <Grid item>
                            <FormLabel mLabel>Spouse Relationship</FormLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <SelectInput
                              fullWidth={true}
                              variant="outlined"
                              label={'Spouse Relationship'}
                              name="spouseRelationship"
                              options={CONSTANTS.spouseRelationshipOptions}
                              value={formValues.spouseRelationship}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Consanguinity status */}
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={5}>
                      <FormLabel component="label" className="form-label">
                        Consanguineous
                      </FormLabel>
                    </Grid>
                    <Grid item xs={7}>
                      <Switch
                        checked={formValues.isConsanguineous}
                        onChange={handleChange}
                        name="isConsanguineous"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* Hide / Unhide */}
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5}>
                <FormLabel component="label" className="form-label">
                  Hide on Pedigree
                </FormLabel>
              </Grid>
              <Grid item xs={7}>
                <Switch
                  checked={formValues.isHide}
                  onChange={handleChange}
                  name="isHide"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="margin-top-3"></div>
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              justifyContent="space-between"
            >
              {/* contact */}
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Mobile
                </FormLabel>
                <TextInput
                  name="mobile"
                  value={formValues.mobile}
                  onChange={(e) => {
                    if (validatePhn(e.target.value) || e.target.value === '')
                      handleChange(e);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  updateParentOnBlur
                />
              </Grid>

              {/* Hospital Number */}
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Hospital Number
                </FormLabel>
                <TextInput
                  name="hospitalNumber"
                  value={formValues.hospitalNumber}
                  onChange={handleChange}
                />
              </Grid>

              {/* Healthcare ID */}
              <Grid item xs={12}>
                <FormLabel component="label" className="form-label">
                  Healthcare ID
                </FormLabel>
                <TextInput
                  name="healthcareId"
                  value={formValues.healthcareId}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={8}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    /* CHECKS VALIDITY OF EMAIL; COMMENTED OUT BECAUSE IT PREVENTS EMAIL BEING REMOVED AND MADE BLANK AGAIN.
                    if (!validateEmail(formValues.email)) {
                      setSnack({
                        status: true,
                        msg: 'Email is not valid',
                        severity: 'warning'
                      });
                      return;
                    }
                      */
                    if (!validDates()) {
                      const element = document.getElementById('top');
                      element.scrollIntoView();
                      setDODErrorText('DOD cannot be in future or before DOB');
                      setDODError(true);
                      if (!isDOBValid()) {
                        setDOBErrorText('DOB cannot be in future or after DOD');
                        setDOBError(true);
                      }
                      setSnack({
                        status: true,
                        msg: 'Dates were not valid',
                        severity: 'warning'
                      });
                      return;
                    }
                    handleSubmit();
                  }}
                  size="medium"
                  variant="contained"
                  color="secondary"
                  disableElevation
                >
                  Save
                </Button>
              </Grid>

              <Grid item xs={4}>
                <Button
                  size="medium"
                  variant="outlined"
                  onClick={handleClose}
                  disableElevation
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default PersonMenu;
