import React from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useAutoTranslation } from 'src/hooks/useTranslate';

export default function SelectInput(props) {
  const {
    value,
    name,
    onChange,
    options,
    variant,
    fullWidth,
    showPlaceholder,
    disabled,
    className,
    validated,
    helperText
  } = props;
  const error = (validated && !value) || props?.error;

  const t = useAutoTranslation();

  return (
    <FormControl
      variant={variant ?? undefined}
      fullWidth={fullWidth ?? undefined}
      disabled={disabled ?? undefined}
      className={className ?? undefined}
      error={props.highlightErrorBorder || error}
      size={props.size || 'medium'}
    >
      <Select
        native
        placeholder={showPlaceholder && 'Select'}
        value={value}
        onChange={onChange}
        inputProps={{
          name: name,
          id: name
        }}
      >
        <option value="" disabled>
          {t(` Select`)}
        </option>
        {options?.map((el, i) => (
          <option value={el.value} key={`${props.name}-${i}`}>
            {t(el.label)}
          </option>
        ))}
      </Select>
      {error && (
        <FormHelperText>
          {t(helperText) || t(props?.error) || t('Please select a valid field')}
        </FormHelperText>
      )}
    </FormControl>
  );
}
