import React from 'react';

import { Box, Typography, Button, LinearProgress } from '@mui/material';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';

const ClinicalNoteDeleteModal = (props) => {
  const { handleCloseModal, loading, onConfirm, title, content, open } = props;

  return (
    <CustomDialog
      open={open}
      handleClose={handleCloseModal}
      title={title}
      titleAppearance={'warning'}
      minHeight={150}
      allowBackgroundClose={handleCloseModal}
    >
      <Box>
        <Typography fontWeight={'bold'}>{content}</Typography>

        {loading && (
          <Box sx={{ width: '100%', pt: 1 }}>
            <Typography fontWeight={'bold'} py={1}>
              Deletion In Progress
            </Typography>
            <LinearProgress />
          </Box>
        )}

        <Box pt={3}>
          <hr />
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'} gap={3} pt={2}>
          <Button
            onClick={handleCloseModal}
            color="secondary"
            variant="outlined"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            color="error"
            variant="contained"
            disabled={loading}
          >
            Confirm Deletion
          </Button>
        </Box>
      </Box>
    </CustomDialog>
  );
};

export default ClinicalNoteDeleteModal;
